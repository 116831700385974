import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import NavPrivate from './NavPrivate';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import JobPostingModal from './ConfirmationModal';
import { CheckSession } from './Auth-Api';

const CreateLocationForm = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isEdit, setIsEdit] = useState(false);
    const [location, setLocation] = useState({});

    const [showModal, setShowModal] = useState(false);
    const [alertHeading, setAlertHeading] = useState('');
    const [alertAction, setAlertAction] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [modalAction, setModalAction] = useState(null);

    const [selectedOption, setSelectedOption] = useState('');
    const [employers, setEmployers] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        addressPrimaryStreet: '',
        addressPrimarySuite: '',
        addressPrimaryCity: '',
        addressPrimaryState: '',
        addressPrimaryZip: '',
        phoneSecondary: '',
        emailSecondary: '',
        googleLink: '',
        locationLat: '',
        locationLong: '',
    });

    CheckSession();
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevData => ({
          ...prevData,
          [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const onKeyDownPrevent = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (isEdit) {
            setShowModal(true);
            setModalAction(() => submit);
            setAlertHeading('Confirm');
            setAlertAction('Edit Location');
            setAlertMessage('Are you sure you want to edit this location?');
        } else {
            submit();
        }
    };

    const submit = () => {
        if (
            !selectedOption ||
            !formData.addressPrimaryStreet ||
            !formData.addressPrimaryCity ||
            !formData.addressPrimaryState ||
            !formData.addressPrimaryZip
        ) {
            alert('Fill out required fields');
            return;
        }

        const payload = {
            locObj: formData,
            refId: selectedOption
        }

        setIsLoading(true);
        if (isEdit) {
            payload.locObj.id = location.id;
            payload.locObj.employerId = selectedOption;
            axios.put('/api/employers/locations', payload.locObj)
            .then(res => {
                setIsLoading(false);
                navigate(`/settings?showToast=Location Updated`);
            })
            .catch(err => {
                setIsLoading(false);
                console.log(err);
                alert(`Error: ${err.response.data.error}`)
            });
        } else {
            axios.post('/api/employers/locations', payload)
            .then(res => {
                setIsLoading(false);
                navigate(`/settings?showToast=Location Created`);
            })
            .catch(err => {
                setIsLoading(false);
                console.log(err);
                alert(`Error: ${err.response.data.error}`)
            });
        }
    };
    

    useEffect(() => {
        const id = searchParams.get('id');

        if (id) {
            axios.get(`/api/employers/locations/${id}`)
            .then(res => {
                console.log('loc', res.data)
                if (res?.data) {
                    const loc = res.data;
                    setIsEdit(true);
                    setLocation(loc);
                    setSelectedOption(loc.employerId)
                    setFormData({
                        addressPrimaryStreet: loc.addressPrimaryStreet || '',
                        addressPrimarySuite: loc.addressPrimarySuite || '',
                        addressPrimaryCity: loc.addressPrimaryCity || '',
                        addressPrimaryState: loc.addressPrimaryState || '',
                        addressPrimaryZip: loc.addressPrimaryZip || '',
                        phoneSecondary: loc.phoneSecondary || '',
                        emailSecondary: loc.emailSecondary || '',
                        googleLink: loc.googleLink || '',
                        locationLat: loc.locationLat || '',
                        locationLong: loc.locationLong || '',
                    });
                }
            })
            .catch(err => {
                console.log('err', err);
                alert(err.response.data?.error || err.response?.data);
            });
        }
    }, []);

    useEffect(() => {
        axios.get(`/api/employers?page=1&limit=1000&sort=nameEmployer&active=true`)
        .then(res => {
            setEmployers(res.data.data)
        })
        .catch(err => {
            console.log('err', err);
            alert('Error');
        });
    }, []);


    const getFieldOrder = [
        'nameEmployer',
        { type: 'divider', text: 'Address info' },
        'addressPrimaryStreet', 'addressPrimarySuite', 'addressPrimaryCity', 'addressPrimaryState', 'addressPrimaryZip',
        { type: 'divider', text: 'Contact info' },
        'phoneSecondary', 'emailSecondary', 'googleLink','locationLat','locationLong',

    ];

    const renderField = (field) => {
        if (typeof field === 'object' && field.type === 'divider') {
            return (
            <div className='borderLine' key={field.text}>
                <h2 className="is-size-5 has-text-weight-bold has-text-black">{field.text}</h2>
            </div>
            );
        }
        
        const fieldName = field ;
    
        const fieldLabels = {
            nameEmployer: 'Employer *',
            addressPrimaryStreet: 'Business address *',
            addressPrimarySuite: 'Suite/number',
            addressPrimaryCity: 'City *',
            addressPrimaryState: 'State *',
            addressPrimaryZip: 'Zip code *',
            phoneSecondary: 'Other phone number',
            emailSecondary: 'Alt email',
            googleLink: 'Google Business Profile',
            locationLat: 'Latitude',
            locationLong: 'Longitude',
        };

        const fieldPlaceholder = {
            nameEmployer: 'What is business name?',
            addressPrimaryStreet: 'What is the business address?',
            addressPrimarySuite: 'Suite/number',
            addressPrimaryCity: 'City',
            addressPrimaryState: 'State',
            addressPrimaryZip: 'Zip code',
            phoneSecondary: 'Add an alternative phone (if available)',
            emailSecondary: 'Alternative email',
            googleLink: 'Add Google link',
            locationLat: 'Latitude (horizontal) - e.g. 37.922367',
            locationLong: 'Longitude (vertical) - e.g. -122.3154715',
        };

        const commonProps = {
            id: fieldName,
            name: fieldName,
            value: formData[fieldName],
        };

        if (fieldName === 'nameEmployer') {
            return (
              <div className="field pt-1" key={fieldName}>
                <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
                <div className="select colorLightBlue">
                    <select
                        className='formInput inputLight is-size-6'
                        value={selectedOption}
                        onChange={handleSelectChange}
                    >
                        <option value="">Select...</option>
                        {employers.map((k, i) => {
                            return <option key={i} value={k.id}>{k.nameEmployer}</option>

                        })}
                    </select>
                </div>
              </div>
            );
        };

    return (
        <div className="field pt-1" key={fieldName}>
            <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
                <div className="control">
                    <input
                        className="input is-size-6 formInput inputLight"
                        type="text"
                        {...commonProps}
                        placeholder= {fieldPlaceholder[fieldName]}
                        onChange={handleInputChange}
                        onKeyDown={onKeyDownPrevent}
                    />
                </div>
        </div>
        );
    };
        
    return (
    <section className="theme-light section m-0 p-0 backgroundWhiteColor" data-theme="light">
        <div className="container backgroundWhiteColor">
            <div className="backgroundWhiteColor fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                <div>
                    <NavPrivate />
                    <div className="theme-light is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 pt-6">
                        <div className="column is-12-mobile is-10-tablet is-8-desktop m-0 p-0">
                            <div>
                                <div className="m-0 p-0 is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                                    <>
                                    <form onSubmit={handleSubmit}>
                                        <div className="is-size-4 my-4 has-text-black is-flex is-flex-direction-column is-justify-content-start is-align-items-center">
                                            <h1 className="has-text-weight-bold">{isEdit ? 'Edit' : 'Create'} <span className='has-text-weight-normal'>Location</span></h1>
                                            <p className='is-size-7 colorLightRed my-3 pb-2'>* Indicates required field</p>
                                        </div>

                                        {getFieldOrder.map((field, index) => renderField(field))}

                                        {(
                                        <>
                                        <div className="field pt-4 mb-6 pb-6 is-grouped is-flex is-justify-content-flex-end is-full">
                                            <div>
                                                <button 
                                                    type="submit"    
                                                    id="submit" 
                                                    className={`button is-size-6 buttonLight ${isLoading ? 'is-loading' : ''}`}
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? 'Loading...' : 'Submit'}
                                                </button>
                                            </div>
                                        </div>
                                        </>
                                        )}
                                    </form>
                                    </>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                {showModal && 
                    <JobPostingModal 
                        alertId="SE001"
                        alertHeading={alertHeading}
                        alertAction={alertAction}
                        alertMessage={alertMessage}
                        alertSubmit={modalAction}
                        close={() => {setShowModal(false)}}
                    />
                }
                <Footer /> 
            </div>
        </div>
    </section>
  );    
};

export default CreateLocationForm;
