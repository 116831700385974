import React from "react";

const AboutProvider = ({ 
    providerDetails,
    locationDetails
}) => {

    return (
        <>
        <div className="p-4">
            <p className='is-size-7 has-text-weight-bold colorLightGreen'>About provider</p>
            <p className='is-size-4 has-text-weight-bold colorDarkGrey pt-2'>{providerDetails?.nameProviderLong}</p>
            <p className='is-size-6 colorDarkGrey has-text-weight-bold pt-2'>{providerDetails?.descriptionProviderShort}</p>
            <p className='is-size-6 colorDarkGrey py-1'>{providerDetails?.descriptionProvider}</p>
            <p className='is-size-6 colorDarkGrey py-2'>Learn more at <a href={locationDetails?.websiteLink}>{locationDetails?.locationUrlTitle}</a>.</p>
        </div>
        </>
    );
};

export default AboutProvider;



