import React, { useEffect, useState} from 'react';
import Footer from './Footer';
import NavPrivate from './NavPrivate';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import JobPostingModal from './ConfirmationModal';
import { CheckSession } from './Auth-Api';

const CreateContactForm = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [showModal, setShowModal] = useState(false);
    const [alertHeading, setAlertHeading] = useState('');
    const [alertAction, setAlertAction] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [modalAction, setModalAction] = useState(null);

    const [employers, setEmployers] = useState([]);
    const [providers, setProviders] = useState([]);
    const [referrals, setReferrals] = useState([]);
    const [caseMans, setCaseMans] = useState([]);
    const [careGivs, setCareGivs] = useState([]);

    const [isEdit, setIsEdit] = useState(false);

    const [user, setUser] = useState({});

    const [isCompleted, setIsCompleted] = useState('');

    const [selectedOption, setSelectedOption] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        avatar: '',
        bio: '',
        personalStatement: '',
        currentJobTitle: '',
        phonePrimary: '',
        phoneSecondary: '',
        emailPrimary: '',
        emailSecondary: '',
        websiteLink: '',
        facebookLink: '',
        twitterLink: '',
        linkedInLink: '',
        instagramLink: '',
        firstName: '',
        lastName: '',
        username: '',
        organization: '',
        selectEmployer: '',
        selectSchool: '',
        selectArchive: '',
        checkboxSend: false,
        checkboxClient: false,
        clientConservatorship: '',
        conservatorName: '',
        conservatorPhone: '',
        conservatorEmail: '',
        linkClient: '',
        emergencyContact: '',
        relationship: '',
        selectCaseManager: '',
        selectReferral: '',
        selectStatus: '',
        dob: '',
        ssn: '',
        cdl: '',
        uci: '',
        addressStreet: '', 
        addressNumber: '', 
        addressCity: '', 
        selectAddressState: '', 
        addressZip: '',
        placementProgram: '',
        intakeInterviewDate: '',
        personInterviewer: '',
        personsPresent: '',
        languageSpoken: '',
        educationLevel: '',
        transportation: '',
        income: '',
        payee: '',
        livingSituation: '',
        selectGender: '',
    });

    const [userAvatar, setUserAvatar] = useState(null);

    CheckSession();

    useEffect(() => {
        axios.get(`/api/employers?page=1&limit=1000&sort=nameEmployer&active=true`)
        .then(res => {
            // console.log('emp', res.data.data)
            setEmployers(res.data.data)
        })
        .catch(err => {
            console.log('err', err);
            alert('Error');
        });

        axios.get(`/api/providers?page=1&limit=1000&sort=nameProviderLong&active=true`)
        .then(res => {
            // console.log('provs', res.data.data)
            setProviders(res.data.data)
        })
        .catch(err => {
            console.log('err', err);
            alert('Error');
        });

        axios.get(`/api/referrals?page=1&limit=1000&sort=nameReferralLong&active=true`)
        .then(res => {
            // console.log('refs', res.data.data)
            setReferrals(res.data.data)
        })
        .catch(err => {
            console.log('err', err);
            alert('Error');
        });

        axios.get(`/api/user?page=1&limit=1000&sort=firstName&role=manager&active=true`)
        .then(res => {
            // console.log('users', res.data.data)
            setCaseMans(res.data.data)
        })
        .catch(err => {
            console.log('err', err);
            alert('Error');
        });

        axios.get(`/api/user?page=1&limit=1000&sort=firstName&role=caregiver&active=true`)
        .then(res => {
            // console.log('caregivs', res.data.data)
            setCareGivs(res.data.data)
        })
        .catch(err => {
            console.log('err', err);
            alert('Error');
        });
    }, []);

    useEffect(() => {
        const id = searchParams.get('id');
        if (id) {
            axios.get(`/api/user/${id}`)
            .then(res => {
                if (res.data.data[0]) {
                    setIsEdit(true);
                    const u = res.data.data[0];
                    console.log('user res', u)
                    setUser(u);
                    setSelectedOption(u.role);

                    let selectArchive = 'active';
                    if (u.active === false) {
                        selectArchive = 'archived';
                    }
                    if (u.accountLocked === true) {
                        selectArchive = 'blocked';
                    }

                    setFormData({
                        firstName: u.firstName || '',
                        lastName: u.lastName || '',
                        username: u.email || '',
                        phonePrimary: u.phone || '',
                        organization: '', // is this used?
                        selectEmployer: u.employer || '',
                        selectSchool: u.school || '',
                        selectArchive: selectArchive,
                        checkboxSend: false,
                        checkboxClient: u.clientHasCaregiver || false,
                        linkClient: u.clientCaregiver || '',
                        emergencyContact: u.clientCaregiverContact || '',
                        relationship: u.clientCaregiverRelationship || '',
                        selectCaseManager: u.clientCaseManager || '',
                        selectReferral: u.referralSource || '',
                        selectStatus: u.clientCurrentStep || '',
                        dob: u.clientDOB || '',
                        ssn: u.clientSSN || '',
                        cdl: u.clientAddressCdlId || '',
                        uci: u.clientAddressUci || '',
                        addressStreet: u.clientAddressStreet || '', 
                        addressNumber: u.clientAddressNumber || '', 
                        addressCity: u.clientAddressCity || '', 
                        selectAddressState: u.clientAddressState || '', 
                        addressZip: u.clientAddressZip || '',
                        placementProgram: u.clientPlacementProgram || '',
                        intakeInterviewDate: u.clientInterviewDate?.split('T')[0] || '',
                        personInterviewer: u.clientInterviewer || '',
                        personsPresent: u.clientInterviewPersonsPresent || '',
                        languageSpoken: u.clientLanguages || '',
                        educationLevel: u.clientEducation || '',
                        transportation: u.clientTransportation || '',
                        income: u.clientIncome || '',
                        payee: u.clientPayee || '',
                        livingSituation: u.clientLivingSituation || '',
                        selectGender: u.clientGender || '',

                        avatar: u.avatar || '',
                        phoneSecondary: u.phoneSecondary || '',
                        emailPrimary: u.emailPrimary || '',
                        emailSecondary: u.emailSecondary || '',
                        bio: u.bio || '',
                        personalStatement: u.personalStatement || '',
                        currentJobTitle: u.currentJobTitle || '',
                        websiteLink: u.websiteLink || '',
                        facebookLink: u.facebookLink || '',
                        twitterLink: u.twitterLink || '',
                        linkedInLink: u.linkedInLink || '',
                        instagramLink: u.instagramLink || '',
                    })
                }
            })
            .catch(err => {
                console.log('err', err);
                alert(err.response.data?.error || err.response.data);
            });
        }
    }, []);

    const navigate = useNavigate();

    const [errors, setErrors] = useState({});

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevData => ({
          ...prevData,
          [name]: type === 'checkbox' ? checked : value
        }));

        setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    };
   
    const handleFileChange = (event) => {
        setUserAvatar({
            selectedFile: event.target.files[0]
        });
    }

    const validateForm = () => {
        const newErrors = {};

        // Required fields
        ['firstName', 'lastName', 'username'].forEach(field => {
          if (!formData[field].trim()) {
            newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
          }
        });
    
        // Email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (formData.username && !emailRegex.test(formData.username)) {
          newErrors.email = 'Invalid email format';
        }
    
        // SSN format (assuming 9 digits, could be more complex)
        const ssnRegex = /^\d{9}$/;
        if (formData.ssn && !ssnRegex.test(formData.ssn)) {
          newErrors.ssn = 'SSN must be 9 digits';
        }
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const onKeyDownPrevent = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (isEdit) {
            setShowModal(true);
            setModalAction(() => submit);
            setAlertHeading('Confirm');
            setAlertAction('Edit Contact');
            setAlertMessage('Are you sure you want to edit this contact?');
        } else {
            submit();
        }
    };
    
    const submit = () => {
        if (!validateForm()) {
            console.log(errors)
            alert('Fill out required fields');
            return;
        }
    
        const payload = {
            user: formData,
            role: selectedOption,
        }
    
        setIsLoading(true);
        if (isEdit) {
            payload.user.id = user.id;
            if (payload.user.selectArchive === 'active') {
                payload.user.active = true;
                payload.user.accountLocked = false;
            } else if (payload.user.selectArchive === 'archived') {
                payload.user.active = false;
                payload.user.accountLocked = false;
            } else if (payload.user.selectArchive === 'blocked') {
                payload.user.accountLocked = true;
            }
            axios.put(`/api/user/${payload.user.id}`, payload.user)
            .then(res => {
                console.log(userAvatar)
                
                if (userAvatar?.selectedFile) {
                    const data = new FormData();
                    data.append('file', userAvatar.selectedFile);
                    axios.post(`/api/user/avatar/${payload.user.id}`, data)
                    .then(ares => {
                        setIsLoading(false);
                        navigate(`/profile?id=${user.id}&showToast=Contact Updated`);
                    })
                    .catch(err => {
                        setIsLoading(false);
                        console.log(err);
                    });
                    // axios.post(`/api/user/avatar/1e43d4b0-8b0f-4612-b900-fa2ae84aa708`, data)
                    // .then(res => {
            
                    // })
                    // .catch(err => {
            
                    // });
                } else {
                    navigate(`/profile?id=${user.id}&showToast=Contact Updated`);
                }
            })
            .catch(err => {
                setIsLoading(false);
                console.log(err);
                // alert(`Error: ${err?.response?.data?.error || err?.response?.data}`); 
            });
        } else {
            axios.post('/api/user/createUser', payload)
            .then(res => {
                console.log(userAvatar)
                setIsLoading(false);

                if (userAvatar?.selectedFile && res.data.id) {
                    const data = new FormData();
                    data.append('file', userAvatar.selectedFile);
                    axios.post(`/api/user/avatar/${res.data.id}`, data)
                    .then(ares => {
                        setIsLoading(false);
                        navigate(`/profile?id=${user.id}&showToast=Contact Updated`);
                    })
                    .catch(err => {
                        setIsLoading(false);
                        console.log(err);
                    });
                } else {
                    navigate('/contacts?showToast=Contact Created');
                }

            })
            .catch(err => {
                setIsLoading(false);
                console.log(err);
                // alert(`Error: ${err?.response?.data?.error || err?.response?.data}}`); 
            });
        }
    }
    
    const getFieldOrder = () => {
        switch(selectedOption) {
            case 'admin':
            case 'coach':
                if (isEdit) {
                    return  [
                        { type: 'divider', text: 'Account' },
                        'selectArchive', 'firstName', 'lastName', 'username', 'checkboxSend',
                        { type: 'divider', text: 'Contact' },
                        'phonePrimary', 'phoneSecondary', 'emailSecondary', 
                        { type: 'divider', text: 'Profile' },
                        'bio', 'personalStatement', 'selectSchool', 'currentJobTitle', 'educationLevel', 'languageSpoken', 'avatar', 
                        { type: 'divider', text: 'Social links' },
                        'websiteLink', 'facebookLink', 'twitterLink', 'linkedInLink', 'instagramLink', 
                    ];
                } else {
                    return  [
                        { type: 'divider', text: 'Account' },
                        'firstName', 'lastName', 'username', 'checkboxSend',
                        { type: 'divider', text: 'Contact' },
                        'phonePrimary', 'phoneSecondary', 'emailSecondary', 
                        { type: 'divider', text: 'Profile' },
                        'bio', 'personalStatement', 'selectSchool', 'currentJobTitle', 'educationLevel', 'languageSpoken', 'avatar', 
                        { type: 'divider', text: 'Social links' },
                        'websiteLink', 'facebookLink', 'twitterLink', 'linkedInLink', 'instagramLink', 
                    ];
                }
            case 'caregiver':
                if (isEdit) {
                    return  [
                        { type: 'divider', text: 'Account' },
                        'selectArchive', 'firstName', 'lastName', 'username',
                        { type: 'divider', text: 'Contact' },
                        'phonePrimary', 'phoneSecondary', 'emailSecondary', 
                        { type: 'divider', text: 'Profile' },
                        'bio', 'personalStatement', 'currentJobTitle', 'educationLevel', 'languageSpoken', 'avatar', 
                        ];
                } else {
                    return  [
                        { type: 'divider', text: 'Account' },
                        'firstName', 'lastName', 'username',
                        { type: 'divider', text: 'Contact' },
                        'phonePrimary', 'phoneSecondary', 'emailSecondary', 
                        { type: 'divider', text: 'Profile' },
                        'bio', 'personalStatement', 'currentJobTitle', 'educationLevel', 'languageSpoken', 'avatar', 
                        ];
                }
            case 'client':
                if (isEdit) {
                    return  [
                        { type: 'divider', text: 'Account' },
                        'selectArchive', 'firstName', 'lastName', 'username', 'checkboxSend',
                        { type: 'divider', text: 'Contact' },
                        'phonePrimary', 'phoneSecondary', 'emailSecondary',
                        { type: 'divider', text: 'Referral' },
                        'selectStatus', 'selectReferral', 'placementProgram', 'selectCaseManager', 'intakeInterviewDate', 'personInterviewer', 'personsPresent', 
                        { type: 'divider', text: 'Personal' },
                        'selectGender', 'dob', 'ssn', 'livingSituation', 'addressStreet', 'addressNumber', 'addressCity', 'selectAddressState', 'addressZip', 'linkClient', 'emergencyContact', 'relationship',
                        { type: 'divider', text: 'Conservatorship' },
                        'clientConservatorship',
                        { type: 'divider', text: 'Additional' },
                        'cdl', 'uci', 'transportation', 'income', 'payee',
                        { type: 'divider', text: 'Profile' },
                        'bio', 'personalStatement', 'currentJobTitle', 'educationLevel', 'languageSpoken', 'avatar',
                        { type: 'divider', text: 'Social links' },
                        'websiteLink', 'facebookLink', 'twitterLink', 'linkedInLink', 'instagramLink',  
                        ];
                } else {
                    return  [
                        { type: 'divider', text: 'Account' },
                        'firstName', 'lastName', 'username', 'checkboxSend',
                        { type: 'divider', text: 'Contact' },
                        'phonePrimary', 'phoneSecondary', 'emailSecondary', 
                        { type: 'divider', text: 'Referral' },
                        'selectStatus', 'selectReferral', 'placementProgram', 'selectCaseManager', 'intakeInterviewDate', 'personInterviewer', 'personsPresent', 
                        { type: 'divider', text: 'Personal' },
                        'selectGender', 'dob', 'ssn', 'livingSituation', 'addressStreet', 'addressNumber', 'addressCity', 'selectAddressState', 'addressZip', 'checkboxClient',
                        { type: 'divider', text: 'Conservatorship' },
                        'clientConservatorship',
                        { type: 'divider', text: 'Additional' },
                        'cdl', 'uci', 'transportation', 'income', 'payee',
                        { type: 'divider', text: 'Profile' },
                        'bio', 'personalStatement', 'currentJobTitle', 'educationLevel', 'languageSpoken', 'avatar',
                        { type: 'divider', text: 'Social links' },
                        'websiteLink', 'facebookLink', 'twitterLink', 'linkedInLink', 'instagramLink',  
                        ];
                }
            case 'provider':
                if (isEdit) {
                    return  [
                        { type: 'divider', text: 'Account' },
                        'firstName', 'lastName', 'username','selectSchool', 'checkboxSend',
                        { type: 'divider', text: 'Contact' },
                        'phonePrimary', 'phoneSecondary', 'emailSecondary', 
                        { type: 'divider', text: 'Personal' },
                        'bio', 'personalStatement', 'currentJobTitle', 'educationLevel', 'languageSpoken', 'avatar', 
                        { type: 'divider', text: 'Social links' },
                        'websiteLink', 'facebookLink', 'twitterLink', 'linkedInLink', 'instagramLink', 
                        ];
                } else {
                    return  [
                        { type: 'divider', text: 'Account' },
                        'firstName', 'lastName', 'username','selectSchool', 'checkboxSend',
                        { type: 'divider', text: 'Contact' },
                        'phonePrimary', 'phoneSecondary', 'emailSecondary', 
                        { type: 'divider', text: 'Personal' },
                        'bio', 'personalStatement', 'currentJobTitle', 'educationLevel', 'languageSpoken', 'avatar', 
                        { type: 'divider', text: 'Social links' },
                        'websiteLink', 'facebookLink', 'twitterLink', 'linkedInLink', 'instagramLink', 
                        ];
                } 
            case 'employer':
                if (isEdit) {
                    return  [
                        { type: 'divider', text: 'Account' },
                        'selectArchive', 'firstName', 'lastName', 'username','selectEmployer', 'checkboxSend',
                        { type: 'divider', text: 'Contact' },
                        'phonePrimary', 'phoneSecondary', 'emailSecondary', 
                        { type: 'divider', text: 'Personal' },
                        'bio', 'personalStatement', 'currentJobTitle', 'educationLevel', 'languageSpoken', 'avatar', 
                        { type: 'divider', text: 'Social links' },
                        'websiteLink', 'facebookLink', 'twitterLink', 'linkedInLink', 'instagramLink', 
                        ];
                } else {
                    return  [
                        { type: 'divider', text: 'Account' },
                        'firstName', 'lastName', 'username','selectEmployer', 'checkboxSend',
                        { type: 'divider', text: 'Contact' },
                        'phonePrimary', 'phoneSecondary', 'emailSecondary', 
                        { type: 'divider', text: 'Personal' },
                        'bio', 'personalStatement', 'currentJobTitle', 'educationLevel', 'languageSpoken', 'avatar', 
                        { type: 'divider', text: 'Social links' },
                        'websiteLink', 'facebookLink', 'twitterLink', 'linkedInLink', 'instagramLink', 
                        ];
                }
            case 'manager':
                if (isEdit) {
                    return  [
                        { type: 'divider', text: 'Account' },
                        'selectArchive', 'firstName', 'lastName', 'username','selectReferral',
                        { type: 'divider', text: 'Contact' },
                        'phonePrimary', 'phoneSecondary', 'emailSecondary', 
                        ];
                } else {
                    return  [
                        { type: 'divider', text: 'Account' },
                        'firstName', 'lastName', 'username','selectReferral',
                        { type: 'divider', text: 'Contact' },
                        'phonePrimary', 'phoneSecondary', 'emailSecondary', 
                        ];
                }
            default:
                return  [];
        }
    };

    const renderField = (field) => {
        if (typeof field === 'object' && field.type === 'divider') {
            return (
            <div className='borderLine' key={field.text}>
                <h2 className="is-size-5 has-text-weight-bold has-text-black">{field.text}</h2>
            </div>
            );
        }
        
        const fieldName = field;
        const fieldLabels = {
        firstName: 'First name *',
        lastName: 'Last name *',
        username: 'Email address *',
        organization: 'Organization',
        selectSchool: 'Provider name',
        selectArchive: 'Account status',
        selectEmployer: 'Business name',
        selectCaseManager: 'Case manager',
        selectReferral: 'Referral source',
        selectStatus: 'Current status',
        addressStreet: 'Address', 
        addressNumber: 'Suite/Apt', 
        addressCity: 'City', 
        selectAddressState: 'State', 
        addressZip: 'Zip code',
        dob: 'Date of birth',
        ssn: 'Social security number',
        cdl: 'CDL/ID',
        uci: 'UCI',
        placementProgram: 'Placement program',
        intakeInterviewDate: 'Intake interview date',
        personInterviewer: 'Interviewer',
        personsPresent: 'Person(s) present',
        languageSpoken: 'Language(s) spoken',
        educationLevel: 'Education level or history',
        transportation: 'Mode of transportation',
        income: 'Source of income',
        payee: 'Payee',
        livingSituation: 'Current living situation',
        selectGender: 'Gender',
        emergencyContact: 'Emergency contact number',
        relationship: 'Relation to client',
        clientConservatorship: 'Is the client under a conservatorship?',
        clientConservatorName: 'Conservator name',
        clientConservatorPhone: 'Conservator phone',
        clientConservatorEmail: 'Conservator email',
        checkboxClient: 'Check if client has a caregiver.',
        linkClient: 'Caregiver name',
        checkboxSend: 'Check to send a welcome email to contact.',
        bio: 'Bio', 
        personalStatement: 'Personal statement', 
        currentJobTitle: 'Job title', 
        avatar: 'Profile photo',
        phonePrimary: 'Phone number',
        phoneSecondary: 'Other number',
        emailPrimary: 'Email',
        emailSecondary: 'Backup email',
        websiteLink: 'Web address',
        facebookLink: 'Facebook link',
        twitterLink: 'Twitter link',
        linkedInLink: 'LinkedIn link',
        instagramLink: 'IG link',
    };

        const fieldPlaceholder = {
        firstName: 'Enter first name',
        lastName: 'Enter last name',
        username: 'Enter email address',
        phonePrimary: 'Enter primary phone',
        organization: 'Enter organization',
        school: 'Enter school name',
        dob: 'Enter DOB',
        ssn: 'Enter SSN',
        cdl: 'Enter CDL/ID number',
        uci: 'Enter UCI',
        placementProgram: 'Enter placement program',
        intakeInterviewDate: 'Enter intake interview date',
        personInterviewer: 'Enter interviewer',
        personsPresent: 'Enter person(s) present',
        languageSpoken: 'Enter language(s) - separate by comma',
        educationLevel: 'Enter education level',
        transportation: 'Enter transportation type',
        addressStreet: 'Enter address', 
        addressNumber: 'Enter suite or apt', 
        addressCity: 'Enter city', 
        addressZip: 'Enter zip',
        income: 'Enter income source',
        payee: 'Enter payee',
        livingSituation: 'Enter living situation',
        emergencyContact: 'Enter emergency contact number',
        relationship: 'Enter emergency relationship',
        clientConservatorName: 'Conservator first and last name',
        clientConservatorPhone: 'Enter a phone number',
        clientConservatorEmail: 'Enter an email',
        bio: 'Provide your employment bio.', 
        personalStatement: 'What is your personal statement?', 
        currentJobTitle: 'What is your current job title?',
        avatar: 'Select your photo...',
        phonePrimary: 'Enter phone as (xxx) yyy-zzzz',
        phoneSecondary: 'Enter phone as (xxx) yyy-zzzz',
        emailPrimary: 'Enter email ',
        emailSecondary: 'Enter an another email',
        websiteLink: 'Start with "https://" ',
        facebookLink: 'Enter link to FB profile',
        twitterLink: 'Enter X link to profile',
        linkedInLink: 'Enter LinkedIn to profile',
        instagramLink: 'Enter IG link to profile',
    };

    if (fieldName === 'clientConservatorship') {
        return (
            <>
            <div className="field mt-4 ml-1 p-0" key="clientConservatorship">
                <label className="label m-1 has-text-black" htmlFor="clientConservatorship">{fieldLabels.clientConservatorship}</label>
                <div className="select colorLightBlue">
                    <select
                        className='formInput'
                        id='clientConservatorship'
                        name='clientConservatorship'
                        // value={formData.clientConservatorship}
                        value={isCompleted} 
                        onChange={(e) => setIsCompleted(e.target.value)}
                    >
                    <option value="">{isCompleted}Select...</option>
                    <option key='yes' value='yes'>Yes</option>
                    <option key='no' value='no'>No</option>
                    </select>
                </div>
            </div>
                
                {isCompleted === 'yes' && (
                // {user.role !== 'manager' ? (
                <>
                    <div className="field mt-4 ml-1 p-0" key="clientConservatorName">
                        <label className="label m-1 has-text-black" htmlFor="clientConservatorName">{fieldLabels.clientConservatorName}</label>
                        <div className="control colorLightBlue">
                        <input
                                className="input inputLight formInput"
                                type="text"
                                id="clientConservatorName"
                                name="clientConservatorName"
                                placeholder="Enter the Conservator first and last name"
                                value={formData.clientConservatorName}
                                onKeyDown={onKeyDownPrevent}
                            />
                        </div>
                    </div>
                    <div className="field mt-4 ml-1 p-0" key="clientConservatorPhone">
                        <label className="label m-1 has-text-black" htmlFor="clientConservatorPhone">{fieldLabels.clientConservatorPhone}</label>
                        <div className="control colorLightBlue">
                        <input
                                className="input inputLight formInput"
                                type="text"
                                id="clientConservatorPhone"
                                name="clientConservatorPhone"
                                placeholder="Provide a phone number"
                                value={formData.clientConservatorPhone}
                                onKeyDown={onKeyDownPrevent}
                            />
                        </div>
                    </div>
                    <div className="field mt-4 ml-1 p-0" key="clientConservatorEmail">
                        <label className="label m-1 has-text-black" htmlFor="clientConservatorEmail">{fieldLabels.clientConservatorEmail}</label>
                        <div className="control colorLightBlue">
                        <input
                                className="input inputLight formInput"
                                type="text"
                                id="clientConservatorEmail"
                                name="clientConservatorEmail"
                                placeholder="Enter email address"
                                value={formData.clientConservatorEmail}
                                onKeyDown={onKeyDownPrevent}
                            />
                        </div>
                    </div>
                </>
                )}
            
            
            </>
        )
    } 
    
    

    if (fieldName === 'checkboxClient' || fieldName === 'checkboxSend') {
        return (
          <div className="field mt-4 ml-1 p-0" key={fieldName}>
            {isEdit === false && 
                <>
                    <input
                        type="checkbox"
                        name={fieldName}
                        checked={formData[fieldName]}
                        onChange={handleInputChange}
                        className=''
                        onKeyDown={onKeyDownPrevent}
                    />
                    <label id="checkbox" className="checkbox ml-2 p-0 is-size-6 has-text-black">{fieldLabels[fieldName]}</label>
                </>
            }
            
            {fieldName === 'checkboxClient' && formData.checkboxClient && (
                <>
                <div className="field pt-1" key="linkClient">
                    <label className="label m-1 has-text-black" htmlFor="linkClient">{fieldLabels.linkClient}</label>
                    <div className="select colorLightBlue">
                        <select
                            className='formInput'
                            id='linkClient'
                            name='linkClient'
                            value={formData.linkClient}
                            onChange={handleInputChange}
                            onKeyDown={onKeyDownPrevent}
                        >
                        <option value="">Select caregiver...</option>
                        {careGivs.map((k, i) => {
                            return <option key={i} value={k.id}>{k.firstName} {k.lastName}</option>
                        })}
                        </select>
                    </div>
                </div>
                <div className="field pt-2">
                <label className="label m-1 has-text-black" htmlFor="emergencyContact">{fieldLabels.emergencyContact}</label>
                    <div className="control">
                        <input
                            className="input inputLight formInput"
                            type="text"
                            id="emergencyContact"
                            name="emergencyContact"
                            placeholder="Search for caregiver"
                            value={formData.emergencyContact}
                            onChange={handleInputChange}
                            onKeyDown={onKeyDownPrevent}
                        />
                    </div>
            </div>
            <div className="field pt-2">
                <label className="label m-1 has-text-black" htmlFor="relationship">{fieldLabels.relationship}</label>
                    <div className="control">
                        <input
                            className="input inputLight formInput"
                            type="text"
                            id="relationship"
                            name="relationship"
                            placeholder="Search for caregiver"
                            value={formData.relationship}
                            onChange={handleInputChange}
                            onKeyDown={onKeyDownPrevent}
                        />
                </div>
            </div>
            </>
            )}
          </div>
        )
    };

    if (fieldName === 'bio' || fieldName === 'educationLevel') {
        return (
          <div className="pt-1" key={fieldName}>
            <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
            <textarea
                className='is-size-6 textarea-fixed-height colorLightBlue'
                placeholder={fieldPlaceholder[fieldName]}
                id= {fieldName}
                name={fieldName}
                value={formData[fieldName]}
                onChange={handleInputChange}
                >
            </textarea>
          </div>
        );
    };

    if (fieldName === 'avatar') {
        return (
            <div className="file is-normal has-name is-fullwidth pt-1" key={fieldName}>
                <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}
                    <div className="file-label is-flex formInput is-fullwidth">
                        <input 
                            className="buttonHeight input file-input is-fullwidth is-size-6 inputLight" 
                            type="file" 
                            value=""
                            // name={fieldName}
                            id={fieldName}
                            onChange={handleFileChange}
                        />
                        <span className="file-cta is-flex align-items-center justify-content-center buttonHeight">
                            <span>Choose a file… </span>
                        </span>
                        <span className="file-name is-size-7 is-flex align-items-center justify-content-center buttonHeight">Min 192px square.</span>
                    </div>
                </label>
            </div>
        );
    };

    if (fieldName === 'selectEmployer') {
        return (
          <div className="field pt-1" key={fieldName}>
            <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
            <div className="select colorLightBlue">
                <select
                    className='formInput'
                    id={fieldName}
                    name={fieldName}
                    value={formData[fieldName]}
                    onChange={handleInputChange}
                    onKeyDown={onKeyDownPrevent}
                >
                <option value="">Select company...</option>
                {employers.map((k, i) => {
                    return <option key={i} value={k.id}>{k.nameEmployer}</option>
                })}
                </select>
            </div>
          </div>
        );
    };

    if (fieldName === 'linkClient') {
        console.log("linkClient=");
        
        <div className="field pt-1" key="linkClient">
            <label className="label m-1 has-text-black" htmlFor="linkClient">{fieldLabels.linkClient}</label>
            <div className="select colorLightBlue">
                <select
                    className='formInput'
                    id='linkClient'
                    name='linkClient'
                    value={formData.linkClient}
                    onChange={handleInputChange}
                    onKeyDown={onKeyDownPrevent}
                >
                <option value="">Select...</option>
                {careGivs.map((k, i) => {
                    return <option key={i} value={k.id}>{k.firstName} {k.lastName}</option>
                })}
                </select>
            </div>
        </div>
    }

    if (fieldName === 'selectAddressState') {
        const states = [
            'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 
            'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 
            'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 
            'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 
            'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 
            'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 
            'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 
            'Wisconsin', 'Wyoming'
            ];
    
        return (
            <div className="field pt-1" key={fieldName}>
                <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
                <div className="select colorLightBlue">
                    <select
                        className='formInput'
                        id={fieldName}
                        name={fieldName}
                        value={formData[fieldName]}
                        onChange={handleInputChange}
                        onKeyDown={onKeyDownPrevent}
                    >
                        <option value="">Select...</option>
                        { states.map((state) => (
                            <option key={state} value={state}>
                                {state}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        );
    };

    if (fieldName === 'selectGender') {
        return (
          <div className="field pt-1" key={fieldName}>
            <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
            <div className="select colorLightBlue">
                <select
                    className='formInput'
                    id={fieldName}
                    name={fieldName}
                    value={formData[fieldName]}
                    onChange={handleInputChange}
                    onKeyDown={onKeyDownPrevent}
                >
                <option value="">Select gender...</option>
                <option value="gender1">Female</option>
                <option value="gender2">Male</option>
                <option value="gender3">Non-binary</option>
                <option value="gender4">Prefer not to say</option>
                </select>
            </div>
          </div>
        );
    };
  
    if (fieldName === 'selectSchool') {
        return (
          <div className="field pt-1" key={fieldName}>
            <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
            <div className="select colorLightBlue">
                <select
                    className='formInput'
                    id={fieldName}
                    name={fieldName}
                    value={formData[fieldName]}
                    onChange={handleInputChange}
                    onKeyDown={onKeyDownPrevent}
                >
                    <option value="">Select...</option>
                    {providers.map((k, i) => {
                        return <option key={i} value={k.id}>{k.nameProviderLong}</option>
                    })}
                </select>
            </div>
          </div>
        );
    };

    if (fieldName === 'selectReferral') {
        return (
          <div className="field pt-1" key={fieldName}>
            <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
            <div className="select colorLightBlue">
                <select
                    className='formInput'
                    id={fieldName}
                    name={fieldName}
                    value={formData[fieldName]}
                    onChange={handleInputChange}
                    onKeyDown={onKeyDownPrevent}
                >
                    <option value="">Select referral source...</option>
                    {referrals.map((k, i) => {
                        return <option key={i} value={k.id}>{k.nameReferralLong}</option>
                    })}
                </select>
            </div>
          </div>
        );
    };

    if (fieldName === 'selectCaseManager') {
        return (
          <div className="field pt-1" key={fieldName}>
            <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
            <div className="select colorLightBlue">
                <select
                    className='formInput'
                    id={fieldName}
                    name={fieldName}
                    value={formData[fieldName]}
                    onChange={handleInputChange}
                    onKeyDown={onKeyDownPrevent}
                >
                    <option value="">Select case manager...</option>
                    {caseMans.map((k, i) => {
                        return <option key={i} value={k.id}>{k.firstName} {k.lastName}</option>
                    })}
                </select>
            </div>
          </div>
        );
    };

    if (fieldName === 'selectArchive' && isEdit) {
        return (
        <div className="field pt-1" key={fieldName}>
            <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
            <div className="select colorLightBlue">
                <select
                    className='formInput'
                    id={fieldName}
                    name={fieldName}
                    value={formData[fieldName]}
                    onChange={handleInputChange}
                    onKeyDown={onKeyDownPrevent}
                >
                    {/* <option value="">Select...</option> */}
                    <option value="active">Active</option>
                    <option value="archived">Archived</option>
                    <option value="blocked">Blocked</option>
                </select>
            </div>
        </div> 
        );
    };

    if (fieldName === 'selectStatus') {
        return (
          <div className="field pt-1" key={fieldName}>
            <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
            <div className="select colorLightBlue">
                <select
                    className='formInput'
                    id={fieldName}
                    name={fieldName}
                    value={formData[fieldName]}
                    onChange={handleInputChange}
                    onKeyDown={onKeyDownPrevent}
                >
                    <option value="">Select client's current status...</option>
                    <option value="intake">Intake</option>
                    <option value="orientation">Orientation</option>
                    <option value="development">Development</option>
                    <option value="exploration">Exploration</option>
                    <option value="placement">Placement</option>
                </select>
            </div>
          </div>
        );
    };

    if (fieldName === 'dob' || fieldName === 'intakeInterviewDate') {
        return (
          <div className="field pt-1" key={fieldName}>
            <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}:</label>
            <div className="control">
                <input
                className="input inputLight formInput"
                type="date"
                id={fieldName}
                name={fieldName}
                value={formData[fieldName]}
                onChange={handleInputChange}
                onKeyDown={onKeyDownPrevent}
                />
            </div>
          </div>
        );
    };

    return (
        <div className="field pt-1" key={fieldName}>
            <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
                <div className="control">
                    <input
                        className="input inputLight formInput"
                        type="text"
                        id={fieldName}
                        name={fieldName}
                        value={formData[fieldName]}
                        placeholder={fieldPlaceholder[fieldName]} 
                        onChange={handleInputChange}
                        onKeyDown={onKeyDownPrevent}
                    />
                </div>
        </div>
      );
    };

    return (
    <section className="theme-light section m-0 p-0 has-background-white" data-theme="light">
        <div className="container">
            <div className="fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                <div>
                    <NavPrivate />
                    <div className="theme-light is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 pt-6">
                        <div className="column is-12-mobile is-10-tablet is-8-desktop m-0 p-0">
                            <div>
                                <div className="m-0 p-0 is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                                    <>
                                    <form onSubmit={handleSubmit}>
                                        <div className="is-size-4 my-4 has-text-black is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                                            <h1 className="has-text-weight-bold">{isEdit ? 'Edit' : 'Create'} <span className='has-text-weight-normal'>Contact</span></h1>
                                            <p className='is-size-7 colorLightRed my-3 pb-2'>* Indicates required field</p>
                                        </div>
                                        <div className="field">
                                            <label htmlFor="options" className="label m-1 has-text-black">Contact role *</label>
                                            <div className="select colorLightBlue">
                                                <select id="options" 
                                                    className="formInput"
                                                    value={selectedOption}
                                                    onChange={handleSelectChange}
                                                    disabled={isEdit}
                                                >
                                                    <option value="">Select role...</option>
                                                    <option value="admin">Admin</option>
                                                    <option value="caregiver">Caregiver</option>
                                                    <option value="manager">Case manager</option>
                                                    <option value="client">Client</option>
                                                    <option value="employer">Employer</option>
                                                    <option value="coach">Job coach</option>
                                                    <option value="provider">Transition provider</option>
                                                </select>
                                            </div>
                                        </div>
                                        
                                        {getFieldOrder().map((field, index) => renderField(field))}

                                        {selectedOption && (
                                        <>
                                        <div className="field pt-4 mb-6 pb-6 is-grouped is-flex is-justify-content-flex-end is-full">
                                            <div>
                                                <button 
                                                    type="submit"    
                                                    id="submit" 
                                                    className={`button is-size-6 buttonLight ${isLoading ? 'is-loading' : ''}`}
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? 'Loading...' : 'Submit'}
                                                </button>
                                            </div>
                                        </div>
                                        </>
                                        )}
                                    </form>
                                    </>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                {showModal && 
                    <JobPostingModal 
                        alertId="SE001"
                        alertHeading={alertHeading}
                        alertAction={alertAction}
                        alertMessage={alertMessage}
                        alertSubmit={modalAction}
                        close={() => {setShowModal(false)}}
                    />
                }
                <Footer /> 
            </div>
        </div>
    </section>
  );    
}

export default CreateContactForm;
