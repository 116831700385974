import React from 'react';
import PhoneNumberDisplay from './PhoneNumberDisplay';

const ProfileContactInfo = ({
    phone,
    phoneSecondary,
    email,
    emailPrimary,
    emailSecondary,
}) => {

    return (
        <div className='contentBox mt-3'>
            <h2 className='is-size-5 has-text-weight-bold mb-2'>Contact details</h2>
            <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Email address</p>
            <p className='colorDarkGrey has-text-weight-normal mb-4 is-size-6'><span><a href={`mailto:{email}`}>{email}</a><br /></span></p>
            
            {emailPrimary ? (
            <>
            <p className='colorDarkGrey has-text-weight-normal mb-4 is-size-6'><span><a href={`mailto:{emailPrimary}`}>{emailPrimary}</a><br /></span></p>
            </>
            ) : (
                <p></p>
            )}

            {emailSecondary ? (
            <>
            <p className='colorDarkGrey has-text-weight-normal mb-4 is-size-6'><span><a href={`mailto:{emailSecondary}`}>{emailSecondary}</a><br /></span></p>
            </>
            ) : (
                <p></p>
            )}

            {phone ? (
            <>
            <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Phone numbers</p>
            <p className='colorDarkGrey has-text-weight-normal mb-2 is-size-6'><span className="is-size-6"><PhoneNumberDisplay number={phone} /><br /></span></p>
            </>
            ) : (
                <p></p>
            )}

            {phoneSecondary ? (
            <>
            <p className='colorDarkGrey has-text-weight-normal mb-4 is-size-6'><span className="is-size-6"><PhoneNumberDisplay number={phoneSecondary} /><br /></span></p>
            </>
            ) : (
                <p></p>
            )}
        </div>
    );
};

export default ProfileContactInfo;