import React, { useEffect, useState } from 'react';
import NavPrivate from './NavPrivate';
import Footer  from './Footer';
import ContactHelpLink from './ContactHelpLink';
import FilterSortModals from  './FilterSortModals';
import Pagination from './Pagination';
import SearchResultsRow from './SearchResultsRow';
import SearchResultsInfo from './SearchResultsInfo';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { CheckSession } from './Auth-Api';

const Search = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchResults, setSearchResults] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [sort, setSort] = useState('lastUpdatedOn');
    const [sortOrder, setSortOrder] = useState(-1);
    const [active, setActive] = useState(true);
    const [filterQry, setFilterQry] = useState('');

    const [search, setSearch] = useState('');

    // const [providers, setProviders] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({
        Category: { 'Contacts': true, 'Jobs': true, 'Skills training': true },
        Status: { 'Active': true, 'Archived': false },
    });

    const [filters, setFilters] = useState({
        Category: ['Contacts', 'Jobs', 'Skills training'],
        Status: ['Active', 'Archived']
    });

    CheckSession();

    const sortOptions = ['Most recent', 'Alpha A-Z', 'Alpha Z-A'];

    // const searchResultsQueryInfo = {
    //     searchResultsQueryContent: [
    //         { resultsTotal: "28", searchDate: "Aug 26, 2024", searchTime: "12:28 PM", searchTerm: "Ama", querySpeed: '0.1932 secs'},
    //     ]
    // };


    // const filters = {
    //     Category: ['Contacts', 'Jobs', 'Skills training',],
    //     Provider: ['Clausen House', 'Laney College', 'Merritt College', 'OUSD'],
    //     Status: ['Active', 'Archived'],
    // };

    // const initialSelectedFilters = {
    //     Category: { 'Contacts': true, 'Jobs': true, 'Skills training': true, },
    //     Provider: { 'Clausen House': true, 'Laney College': true, 'Merritt College': true, 'OUSD': true, },
    //     Status: { 'Active': true },
    // };

    // const sortOptions = ['Most recent', 'By category', 'By provider'];
    
    const handlePageChange = (page) => {
        setCurrentPage(page)
    }

    const handleFilterChange = (updatedFilters) => {

        if ((updatedFilters.Status.Active && updatedFilters.Status.Archived) || (!updatedFilters.Status.Active && !updatedFilters.Status.Archived)) {
            setActive(undefined);
        } else if (updatedFilters.Status.Active) {
            setActive(true);
        } else if (updatedFilters.Status.Archived) {
            setActive(false);
        }

        let filterStr = '';

        if (updatedFilters.Category.Contacts) {
            filterStr += `&category=contacts`;
        }
        if (updatedFilters.Category.Jobs) {
            filterStr += `&category=jobs`;
        }
        if (updatedFilters.Category && updatedFilters.Category['Skills training']) {
            filterStr += `&category=skills`;
        }

        // for (let i = 0; i < providers.length; i++) {
        //     const p = providers[i];

        //     if (updatedFilters.Provider && updatedFilters.Provider[p.nameProviderLong]) {
        //         filterStr += `&providers=${p.id}`;
        //     }
        // }

        setFilterQry(filterStr);
        setSelectedFilters(updatedFilters);
    };

    const handleSortChange = (newSort) => {
        // Update your search results based on the new sort option
        switch(newSort) {
            case 'Most recent':
                setSort('createdOn');
                setSortOrder('-1');
                setCurrentPage(1);
                break;
            case 'Alpha A-Z':
                setSort('search');
                setSortOrder('1');
                setCurrentPage(1);
                break;
            case 'Alpha Z-A':
                setSort('search');
                setSortOrder('-1');
                setCurrentPage(1);
                break;
        }
    };

    const handleSearchChange = (searchTerm) => {
        setSearch(searchTerm)
    }

    // useEffect(() => {
    //     axios.get(`/api/providers?page=1&limit=1000&sort=nameProviderLong&active=true`)
    //     .then(res => {
    //         const provs = res.data.data;
    //         const ids = {};
    //         const names = [];
    //         provs.forEach(p => {
    //             ids[p.nameProviderLong] = true;
    //             names.push(p.nameProviderLong);
    //         });

    //         setProviders(provs);
            
    //         setSelectedFilters({
    //             Category: { 'Contacts': true, 'Jobs': true, 'Skills training': true },
    //             Provider: ids,
    //             Status: { 'Active': true, 'Archived': false },
    //         });
    //         setFilters({
    //             Category: ['Contacts', 'Jobs', 'Skills training'],
    //             Provider: names,
    //             Status: ['Active', 'Archived']
    //         });
    //     })
    //     .catch(err => {
    //         console.log('err', err);
    //     });
    // }, []);

    useEffect(() => {
        const searchStr = searchParams.get('search');

        if (search || searchStr) {
            setSearch(search || searchStr);
            axios.get(`/api/search?page=${currentPage}&limit=12&sort=${sort}&sortOrder=${sortOrder}&active=${active}${filterQry}&search=${search || searchStr}`)
            .then(res => {
                setPages(res.data.metaData.totalPages);
                setCurrentPage(parseInt(res.data.metaData.pageNumber || 1));
                setTotalResults(res.data.metaData.totalDocuments || 0);
                setSearchResults(res.data.data);
            })
            .catch(err => {
                console.log('err', err);
            });
        }
    }, [currentPage, sort, sortOrder, active, search, filterQry]);

    return (
        <section className="backgroundWhiteColor theme-light section m-0 p-0" data-theme="light">
            <div className="container">
                <div className="fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                    <div>
                        <NavPrivate handleSearchChange={handleSearchChange} />
                        <div className="theme-light is-full is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 pt-6">
                            <div className="column is-12-mobile is-10-tablet is-10-desktop m-0 p-0">
                                <div>
                                    <div className="m-0 p-0">
                                        <div className="is-flex is-flex-direction-column is-align-content-flex-start my-4">
                                            <div className='is-flex is-flex-direction-row is-fullwidth is-justify-content-space-between is-align-items-center'>
                                                <h1 className="pl-5 is-size-3 has-text-weight-bold has-text-black">Search results <span className="is-size-5 has-text-weight-normal"></span></h1>
                                                <div className='is-flex is-flex-direction-row is-align-items-center'>
                                                {selectedFilters && <FilterSortModals 
                                                    filters={filters} 
                                                    initialSelectedFilters={selectedFilters}
                                                    sortOptions={sortOptions}
                                                    initialSort="Most recent"
                                                    onFilterChange={handleFilterChange}
                                                    onSortChange={handleSortChange}
                                                />}
                                                    <ContactHelpLink />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='boxDivGrey boxDivMinHeight mb-6'>
                                            <div className='centerStuffJustWide m-0 p-0'>
                                                <div className='m-0 p-0'>
                                                    <SearchResultsInfo resultsTotal={totalResults} searchTerm={search}/>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='centerStuffJustWide'>
                                                    <SearchResultsRow searchResultContent={searchResults} currentPage={currentPage}/>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            totalResults > 12 && (
                                                <Pagination pages={pages} currentPage={currentPage} updatePage={handlePageChange}/>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </div>
                    <Footer /> 
                </div>
            </div>
        </section>
    )
}

export default Search;