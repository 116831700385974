import React from 'react';
import { dateHelper, timeHelper } from '../utils.js/utils';

const DashboardDataActivity = ({
    DataActivityContent,
}) => {
    
    const isYes = (value) => value.toLowerCase() === 'yes';

    return (
        <div className='dataBarHeight'>
            <div className='m-2 is-flex is-flex-direction-column is-justify-content-space-between is-align-items-start'>
                
            {DataActivityContent.map((data, index) => {

                return (
                    <>
                        <div key={index} className="width-6 oneHundredPercentWidth is-2 mt-1 mb-1">
                            <p className='pt-1 my-1 has-text-left is-size-6 has-text-weight-normal colorDarkGrey'><span className='is-size-7 has-text-weight-bold colorLightGreen'>{dateHelper(data.createdOn)}  {timeHelper(data.createdOn)}</span><br /><span>{data.userDetails.firstName || ''} {data.userDetails.lastName || ''} {data.message}</span></p>
                        </div>
                    </>   
                );
            })}
            </div>
        </div>
    );
  };

export default DashboardDataActivity;