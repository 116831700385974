import React from "react";

const LimitText = ({ text, limit }) => {
    if (text.length <= limit) {
        return <p>{text}</p>;
    }
    
    const truncatedText = text.slice(0, limit);
    const lastSpaceIndex = truncatedText.lastIndexOf(' ');
    const finalText = truncatedText.slice(0, lastSpaceIndex);
    
    return (
        <p>
        {finalText}
        {text.length > limit && '...'}
        </p>
    );
};

export default LimitText;



