import React, { useEffect, useState } from 'react';
import NavPrivate from './NavPrivate';
import Footer  from './Footer';
import ContactHelpLink from './ContactHelpLink';
import FilterSortModals from './FilterSortModals';
import DashboardDataBar from './DashboardDataBar';
import DashboardDataRecent from './DashboardDataRecent';
import DashboardDataLatestJobs from './DashboardDataLatestJobs';
import DashboardDataTopSkills from './DashboardDataTopSkills';
import DashboardDataActivity from './DashboardDataActivity.js';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import JobPostingToast from './ConfirmationToast';
import { CheckSession } from './Auth-Api.js';

const Dashboard = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [toastMessage, setToastMessage] = useState('');
    const [user, setUser] = useState({});
    const [dashboardDataRecentProfiles, setDashboardDataRecentProfiles] = useState([]);
    const [dashboardActiveJobs, setDashboardActiveJobs] = useState([]);
    const [dashboardDataTopSkills, setDashboardDataTopSkills] = useState([]);
    const [dashboardActivityStream, setDashboardActivityStream] = useState([]);
    const [dashboardDataBar, setDashboardDataBar] = useState([]);
    const [dashboardDataTotal, setDashboardDataTotal] = useState(0);
    
    CheckSession();

    const DashboardDataBarMetrics = {
        DataBarContent: [
            { title: "Total contacts", divider: "no", calculation: "2,563", calculationChange: "positive", comparedData: "+11% vs. last 90 days", percentageChange: "+11% vs. last 90 days", },
            { title: "Intake", divider: "no", calculation: "96%", calculationChange: "positive", comparedData: "246 of total", percentageChange: "+22% vs. last 90 days", },
            { title: "Orientation", divider: "no", calculation: "88%", calculationChange: "negative", comparedData: "225 of total", percentageChange: "-1% vs. last 90 days", },
            { title: "Development", divider: "no", calculation: "77%", calculationChange: "positive", comparedData: "197 of total", percentageChange: "+125% vs. last 90 days", },
            { title: "Exploration", divider: "no",calculation: "58%", calculationChange: "positive", comparedData: "148 of total", percentageChange: "+12% vs. last 90 days", },
            { title: "Placement", divider: "no", calculation: "32%", calculationChange: "positive", comparedData: "82 of total", percentageChange: "+41% vs. last 90 days" }, 
        ]
    };

    const filters = {
        Employer: ['Safeway',],
        Status: ['Active', 'Archived'],
    };

    const initialSelectedFilters = {
        Employer: { 'Safeway': true},
        Status: { 'Active': true },
    };

    const sortOptions = ['Last 90 days', 'Last 180 days', 'Last 270 days', 'Year to date'];

    const handleFilterChange = (updatedFilters) => {
        // Handle the filter change, e.g., update search results
        // console.log('Filters updated:', updatedFilters);
    };

    const handleSortChange = (newSort) => {
        // console.log('Sort:', newSort);
        // Update your search results based on the new sort option
    };

    useEffect(() => {
        const id = searchParams.get('showToast');
        if (id) {
            setToastMessage(id);
        }

        axios.get(`/api/user?page=1&limit=5&active=true&sort=lastUpdatedOn&sortOrder=-1`)
        .then(res => {
            setDashboardDataRecentProfiles(res.data.data);
        })
        .catch(err => {
            // TODO JASON: handle err
            console.log('err', err);
        });

        axios.get(`/api/jobs?page=1&limit=5&active=true&sort=lastUpdatedOn&sortOrder=-1`)
        .then(res => {
            setDashboardActiveJobs(res.data.data);
        })
        .catch(err => {
            // TODO JASON: handle err
            console.log('err', err);
        });

        axios.get(`/api/skills?page=1&limit=5&active=true&sort=views&sortOrder=-1`)
        .then(res => {
            setDashboardDataTopSkills(res.data.data);
        })
        .catch(err => {
            // TODO JASON: handle err
            console.log('err', err);
        });

        axios.get(`/api/activityStreams?page=1&limit=12&sort=createdOn&sortOrder=-1`)
        .then(res => {
            // console.log('res act', res);
            setDashboardActivityStream(res.data.data);
        })
        .catch(err => {
            // TODO JASON: handle err
            console.log('err act stream', err);
        });

        axios.get('/api/user/clientcounts/aggs')
        .then(res => {
            setDashboardDataBar(res.data.aggs);
            setDashboardDataTotal(res.data.total);
        })
        .catch(err => {
            console.log('err client agg', err);
        });

        axios.get('/api/user/myaccount')
        .then(res => {
            if (res?.data.data[0]) {
                setUser(res.data.data[0]);
            }
        })
        .catch(err => {
            console.log('err dash my account', err);
        });

    }, []);

    return (
        <section className="backgroundLightGreyColor theme-light section m-0 p-0" data-theme="light">
            <div className="container">
                <div className="fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                    <div>
                        <NavPrivate />
                        <div className="theme-light is-full is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 pt-6">
                            <div className="column is-12-mobile is-10-tablet is-10-desktop m-0 p-0">
                                <div>
                                    <div className="m-0 p-0">
                                        <div className="is-flex is-flex-direction-column is-align-content-flex-start my-4">
                                            <div className='pb-3 is-flex is-flex-direction-row is-fullwidth is-justify-content-space-between is-align-items-center'>
                                                <h1 className="is-size-3 has-text-weight-bold has-text-black">👋 Hi, {user.firstName}</h1>
                                                <div className='is-flex is-flex-direction-row is-align-items-center'>
                                                    <FilterSortModals 
                                                        filters={filters} 
                                                        initialSelectedFilters={initialSelectedFilters}
                                                        sortOptions={sortOptions}
                                                        initialSort="Last 90 days"
                                                        onFilterChange={handleFilterChange}
                                                        onSortChange={handleSortChange}
                                                        hideShow="filters"
                                                    />
                                                    <ContactHelpLink />
                                                </div>
                                            </div>
                                        </div>
                                        <DashboardDataBar aggs={dashboardDataBar} total={dashboardDataTotal} />
                                        <div className='pb-6 is-flex is-flex-direction-row is-flex-wrap-wrap is-justify-content-space-between'>
                                            <div className='column my-2 py-2 boxDiv is-flex is-two-thirds is-full-mobile is-flex-direction-rows is-flex-wrap-wrap'>
                                                {/* Left side */}
                                                <div className='column is-flex is-full-mobile is-flex-direction-column percentFullHeight'>
                                                    <div className='columns is-flex is-flex-direction-row'>
                                                        <div className='boxDiv is-flex-grow-1 is-flex is-flex-direction-column my-2 p-4'>
                                                            <div className=''>
                                                                <p className='is-size-5 pl-2 pt-1 has-text-weight-bold colorDarkGrey'>Latest updated contacts</p>
                                                            </div>
                                                            <DashboardDataRecent dashboardDataRecentProfiles={dashboardDataRecentProfiles} />
                                                        </div>
                                                    </div>
                                                    <div className='columns is-flex is-flex-direction-row percentFullHeight'>
                                                        <div className='column my-2 p-4 boxDiv'>
                                                            <div className=''>
                                                                <p className='is-size-5 pl-2 pt-1 has-text-weight-bold colorDarkGrey'>Active jobs</p>
                                                            </div>
                                                            <DashboardDataLatestJobs DataLatestContent={dashboardActiveJobs} />
                                                        </div>
                                                        <div className='column my-2 p-4 boxDiv'>
                                                            <div className=''>
                                                                <p className='is-size-5 pl-2 pt-1 has-text-weight-bold colorDarkGrey'>Top training</p>
                                                            </div>
                                                            <DashboardDataTopSkills DataSkillsContent={dashboardDataTopSkills} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='percentFullHeight my-2 p-4 column boxDiv is-flex is-flex-direction-row '>
                                                {/* Right side */}
                                                <div className='column boxDiv percentFullHeight'>
                                                    <p className='is-size-5 pl-2 pt-1 has-text-weight-bold colorDarkGrey'>Activity stream</p>
                                                    <DashboardDataActivity DataActivityContent={dashboardActivityStream} />
                                                    <div className='boxDivMinHeight'>
                                                        {/* Keep this div */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </div>
                    {toastMessage && <JobPostingToast toastMessage={toastMessage} close={() => {setToastMessage('')}}/>}
                    <Footer /> 
                </div>
            </div>
        </section>
    )
}

export default Dashboard;