import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import NavPrivate from './NavPrivate';
import { useNavigate, useSearchParams } from 'react-router-dom';
import JobPostingModal from './ConfirmationModal';

import axios from 'axios';
import { CheckSession } from './Auth-Api';

const AddCertificationForm = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [user, setUser] = useState({});

    const [clientId, setClientId] = useState('');
    const [year, setYear] = useState('');
    const [programName, setProgramName] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [alertHeading, setAlertHeading] = useState('');
    const [alertAction, setAlertAction] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [modalAction, setModalAction] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    CheckSession();
    const navigate = useNavigate();

    const onKeyDownPrevent = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
        }
    }

    useEffect(() => {
        const id = searchParams.get('id');
        if (!id) {
            navigate('/contacts?showToast=No Contact')
        }
        setClientId(id);

        axios.get(`/api/user/${id}`)
        .then(res => {
            const u = res?.data?.data[0];
            if (u) {
                setUser(u);
            }
        })
        .catch(err => {
            console.log('err user', err);
        });
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setShowModal(true);
        setModalAction(() => submit);
        setAlertHeading('Confirm');
        setAlertAction('Create Certification');
        setAlertMessage('Are you sure you want to create this certification?');
    };

    const submit = () => {
        console.log(year, programName)
        if (!year || !programName) {
            alert('Fill out required fields');
            return;
        }
        
        const payload = {
            certObj: {
                year: year,
                programName: programName,
                clientId: clientId,
                firstName: user.firstName,
                lastName: user.lastName,
            }
        };
        
        setIsLoading(true);
        axios.post('/api/clientCertifications', payload)
        .then(res => {
            setIsLoading(false);
            navigate(`/profile?id=${clientId}`);
        })
        .catch(err => {
            setIsLoading(false);
            console.log(err);
        });
    };

    return (
        <section className="theme-light section m-0 p-0 backgroundWhiteColor" data-theme="light">
        <div className="container backgroundWhiteColor">
            <div className="backgroundWhiteColor fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                <div>
                    <NavPrivate />
                    <div className="theme-light is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 pt-6">
                        <div className="column is-10-mobile is-8-tablet is-6-desktop m-0 p-0">
                            <div>
                                <div className="m-0 p-0 is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                                    <>
                                    {/* This is new code below */}
                                    <div className="has-text-black">
                                        <div className=""></div>
                                            <form onSubmit={handleSubmit}>
                                                <div className="is-size-4 my-4 has-text-black">
                                                    <h1 className="has-text-weight-bold has-text-black">Add a certification to {user.firstName}'s profile.</h1>
                                                </div>
                                                <div className="field">
                                                    <label htmlFor="name" className="label mt-5 m-1 has-text-black">Year completed</label>
                                                    <div className="select colorLightBlue">
                                                    <select
                                                        className='formInput'
                                                        onChange={(e) => setYear(e.target.value)}
                                                        onKeyDown={onKeyDownPrevent}
                                                    >
                                                        <option value="">Select...</option>
                                                        <option key='2024' value='2024'>2024</option>
                                                        <option key='2023' value='2023'>2023</option>
                                                        <option key='2022' value='2022'>2022</option>
                                                        <option key='2021' value='2021'>2021</option>
                                                        <option key='2020' value='2020'>2020</option>
                                                        <option key='2019' value='2019'>2019</option>
                                                        <option key='2018' value='2018'>2018</option>
                                                        <option key='2017' value='2017'>2017</option>
                                                        <option key='2016' value='2016'>2016</option>
                                                        <option key='2015' value='2015'>2015</option>
                                                        <option key='2014' value='2014'>2014</option>
                                                        <option key='2013' value='2013'>2013</option>
                                                        <option key='2012' value='2012'>2012</option>
                                                        <option key='2011' value='2011'>2011</option>
                                                        <option key='2010' value='2010'>2010</option>
                                                    </select>
                                                    </div>
                                                </div>
                                                <div className="field">
                                                    <label htmlFor="pass" className="label mt-5 m-1 has-text-black">Certification program name</label>
                                                    <div className="control has-icons-right">
                                                        <input 
                                                            id="pass" 
                                                            className="input inputDark" 
                                                            type="text" 
                                                            placeholder="Enter program name"
                                                            onChange={(e) => {setProgramName(e.target.value)}}
                                                            onKeyDown={onKeyDownPrevent}
                                                        />
                                                        <span className="icon is-small is-right">
                                                            <i className="fas fa-check"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="field is-grouped is-flex is-justify-content-flex-end is-full">
                                                    <div>
                                                        <button 
                                                            id="submit" 
                                                            className={`mt-5 button is-size-6 adaNo buttonLight ${isLoading ? 'is-loading' : ''}`}
                                                            disabled={isLoading}
                                                        >
                                                            {isLoading ? 'Loading...' : 'Submit'}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                    </div>
                                    {/* This is end of new code */}
                                    </>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                {showModal && 
                    <JobPostingModal 
                        alertId="SE001"
                        alertHeading={alertHeading}
                        alertAction={alertAction}
                        alertMessage={alertMessage}
                        alertSubmit={modalAction}
                        close={() => {setShowModal(false)}}
                    />
                }
                <Footer /> 
            </div>
        </div>
    </section>
  );    
}

export default AddCertificationForm;
