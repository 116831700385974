import React from 'react';

const RoleBasedProfileInfo = ({ 
    role,
    currentJobTitle,
    selectSchool,
    selectReferral,
    selectEmployer,
    connectedCaregiver,
    connectedClient,
 }) => {
  const renderContent = () => {
    switch (role) {
        case 'admin':
        case 'coach':
        case 'provider':
        return (
            <p className='is-size-7 p-0 has-text-weight-bold colorDarkGrey'>{currentJobTitle} {selectSchool ? 'at ' : ''} {selectSchool}</p>
        );
      case 'employer':
        return (
            <p className='is-size-7 p-0 has-text-weight-bold colorDarkGrey'>{currentJobTitle} {selectEmployer ? 'at ' : ''} {selectEmployer}</p>
        );
      case 'caregiver':
        return (
            <p className='is-size-7 p-0 has-text-weight-bold colorDarkGrey'>{connectedClient ? 'Caregiver for' : ''} {connectedClient}</p>
        );
      case 'client':
        return (
            <p className='is-size-7 p-0 has-text-weight-bold colorDarkGrey'>{connectedCaregiver ? 'Caregiver is' : ''} {connectedCaregiver} {currentJobTitle} {selectSchool ? 'at ' : ''} {selectSchool}</p>
        );
      default:
        return (
            <p className='is-size-7 p-0 has-text-weight-bold colorDarkGrey'>{currentJobTitle} {selectReferral ? 'at ' : ''} {selectReferral}</p>
        );
    }
  };

  return (
    <div className="container">
      {renderContent()}
    </div>
  );
};

export default RoleBasedProfileInfo;