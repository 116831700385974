import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import NavPrivate from './NavPrivate';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import JobPostingModal from './ConfirmationModal';
import { CheckSession } from './Auth-Api';

const CreateSkillForm = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isEdit, setIsEdit] = useState(false);
    const [skill, setSkill] = useState({});

    const [showModal, setShowModal] = useState(false);
    const [alertHeading, setAlertHeading] = useState('');
    const [alertAction, setAlertAction] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [modalAction, setModalAction] = useState(null);

    const [selectedOption, setSelectedOption] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [providers, setProviders] = useState([]);
    const [providerLocations, setProviderLocations] = useState([]);

    const [formData, setFormData] = useState({
        selectSchool: '',   // is this used?
        selectLocation: '',
        skillTitle: '',
        skillTags: '',
        skillDescription: '',
        skillDescriptionShort: '',
        skillNote: '',
        skillCategory: '',
        skillLocation: '',
        locationLat: '',
        locationLong: '',
        skillOutcomes: '',
        skillNumber: '',
        skillDetails: '',
        skillLength: '',
        skillCosts: '',
        skillCredits: '',
        skillInsructorName: '',
        skillInstructorBio: '',
        skillStartDate: '',
        skillEndDate: '',
        skillInstructorPhoto: ''
    });

    CheckSession();
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`/api/providers?page=1&limit=1000&sort=nameProviderLong&active=true`)
        .then(res => {
            setProviders(res.data.data)
        })
        .catch(err => {
            console.log('err', err);
            alert('Error');
        });
    }, []);

    useEffect(() => {
        if (!selectedOption) return;

        axios.get(`/api/providers/locations?page=1&limit=1000&sort=addressPrimaryStreet&providerId=${selectedOption}`)
        .then(res => {
            setProviderLocations(res.data.data);
        })
        .catch(err => {
            console.log('err', err);
            alert('Error');
        });
    }, [selectedOption]);

    useEffect(() => {
        const id = searchParams.get('id');

        if (id) {
            axios.get(`/api/skills/${id}`)
            .then(res => {
                console.log('skllls res', res.data)
                if (res?.data?.data[0]) {
                    const s = res.data.data[0];
                    setIsEdit(true);
                    setSkill(s);
                    setSelectedOption(s.selectSchool)
                    setFormData({
                        selectSchool: s.selectSchool || '',
                        selectLocation: s.selectLocation || '',
                        skillTitle: s.skillTitle || '',
                        skillTags: s.skillTags || '',
                        skillDescription: s.skillDescription || '',
                        skillDescriptionShort: s.skillDescriptionShort || '',
                        skillNote: s.skillNote || '',
                        skillCategory: s.skillCategory || '',
                        skillLocation: s.skillLocation || '',
                        skillOutcomes: s.skillOutcomes || '',
                        skillNumber: s.skillNumber || '',
                        skillDetails: s.skillDetails || '',
                        skillLength: s.skillLength || '',
                        skillCosts: s.skillCosts || '',
                        skillCredits: s.skillCredits || '',
                        skillInsructorName: s.skillInsructorName || '',
                        skillInstructorBio: s.skillInstructorBio || '',
                        skillStartDate: s.skillStartDate?.split('T')[0] || '',
                        skillEndDate: s.skillEndDate?.split('T')[0] || '',
                        skillInstructorPhoto: s.skillInstructorPhoto || ''
                    });

                }
            })
            .catch(err => {
                console.log('err', err);
                alert(err.response.data?.error || err.response?.data);
            });
        }
    }, []);

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevData => ({
          ...prevData,
          [name]: type === 'checkbox' ? checked : value
        }));
    };

    const onKeyDownPrevent = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (isEdit) {
            setShowModal(true);
            setModalAction(() => submit);
            setAlertHeading('Confirm');
            setAlertAction('Edit Skill');
            setAlertMessage('Are you sure you want to edit this skill?');
        } else {
            submit();
        }
    };

    const submit = () => {
        if (!formData.selectLocation || !formData.skillTitle || !formData.skillDescription || !formData.skillDescriptionShort || !formData.skillCategory) {
            alert('Fill out required fields');
            return;
        }
        
        const payload = {
            skillObj: formData,
            provider: selectedOption,
        };

        const provObj = providers.find(p => p.id === selectedOption)
        if (provObj?.nameProviderLong) payload.nameProviderLong = provObj?.nameProviderLong;
        
        setIsLoading(true);
        if (isEdit) {
            payload.skillObj.id = skill.id;
            axios.put(`/api/skills/${payload.skillObj.id}`, payload)
            .then(res => {
                setIsLoading(false);
                navigate(`/course?id=${payload.skillObj.id}&showToast=Skill Updated`);
            })
            .catch(err => {
                setIsLoading(false);
                console.log(err);
                alert(`Error: ${err.response.data.error}`)
            });
        } else {
            axios.post('/api/skills', payload)
            .then(res => {
                setIsLoading(false);
                navigate('/skills?showToast=Skill Created');
            })
            .catch(err => {
                setIsLoading(false);
                console.log(err);
                alert(`Error: ${err.response.data.error}`)
            });
        }
    };

    const getFieldOrder = () => {
        if (selectedOption) {
            return  [
                    { type: 'divider', text: 'Course info' },
                    'selectLocation', 'skillTitle', 'skillDescriptionShort', 'skillDescription', 'skillCategory',
                    { type: 'divider', text: 'Additional details' },
                    'skillTags', 'skillLocation', 'skillOutcomes', 'skillNumber', 'skillStartDate', 'skillEndDate', 'skillLength', 'skillCredits', 'skillCosts', 'skillNote',
                    { type: 'divider', text: 'Instructor details' },
                    'skillInsructorName', 'skillInstructorBio', 'skillInstructorPhoto',
                    ];
        } else {
            return  [];
        }
        // switch(selectedOption) {
        //     case 'school1':
        //     case 'school2':
        //     case 'school3':  
        //         return  [
        //                 { type: 'divider', text: 'Course info' },
        //                 'selectLocation', 'skillTitle', 'skillDescription', 'skillDescriptionShort', 'skillCategory',
        //                 { type: 'divider', text: 'Additional details' },
        //                 'skillTags', ' skillLocation', 'skillOutcomes', 'skillNumber', 'skillStartDate', 'skillEndDate', 'skillLength', 'skillCredits', 'skillCosts', 'skillNote',
        //                 { type: 'divider', text: 'Instructor details' },
        //                 'skillInsructorName', 'skillInstructorBio', 'skillInstructorPhoto',
        //                 ];
        //     default:
        //         return  [];
        // }
    };

    const renderField = (field) => {
        if (typeof field === 'object' && field.type === 'divider') {
            return (
            <div className='borderLine' key={field.text}>
                <h2 className="is-size-5 has-text-weight-bold has-text-black">{field.text}</h2>
            </div>
            );
        }
    
    const fieldName = field;
    
    const fieldLabels = {
        selectSchool: 'Transition provider (school) *',
        selectLocation: 'Provider location *',
        skillTitle: 'Course title  *',
        skillTags: 'Tags',
        skillDescription: 'Course description *',
        skillOutcomes: 'Desired outcomes',
        skillNumber: 'Course number',
        skillDetails: 'Details',
        skillStartDate: 'Start date',
        skillEndDate: 'End date',
        skillLength: 'Course length',
        skillCredits: 'Credits',
        skillCosts: 'Costs',
        skillInsructorName: 'Trainer name',
        skillInstructorBio: 'Bio',
        skillInstructorPhoto: 'Photo',
        skillDescriptionShort: 'Text underneath title *',
        skillNote: 'Course note',
        skillCategory: 'Course category *',
        skillLocation: 'Course location *',
    };

    const fieldPlaceholder = {
        skillTitle: 'What is the title of the skills training course?',
        skillTags: 'Separate features by commas (e.g., in-person, ...).',
        skillDescription: 'Provide a brief overview of the training program.',
        skillOutcomes: 'List desired outcomes. \nUse line breaks or commas for bullets.',
        skillNumber: 'Enter course number',
        skillDetails: 'Include any additional details. \nUse line breaks or commas for bullets.',
        skillStartDate: 'Enter start date.',
        skillEndDate: 'Enter end date.',
        skillCredits: 'What credits/certification are available?',
        skillLength: 'Enter days per week and times.',
        skillCosts: 'Training program costs (e.g., $225 per unit).',
        skillInsructorName: 'What is the name of the instructor?',
        skillInstructorBio: 'Provide a brief instructor bio.',
        skillInstructorPhoto: 'Upload instructor photo...',
        skillDescriptionShort: 'Provider a short description...',
        skillNote: 'Add note (e.g., Starts June 17)...',
        skillCategory: 'Enter a course category (e.g., Food Service)',
    };

    const commonProps = {
        id: fieldName,
        name: fieldName,
        value: formData[fieldName],
    };

    if (fieldName === 'selectLocation') {
        return (
          <div className="field pt-1" key={fieldName}>
            <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
            <div className="select colorLightBlue">
                <select
                    className='formInput inputLight is-size-6'
                    {...commonProps}
                    onChange={handleInputChange}
                >
                <option value="">Select location...</option>
                {providerLocations.map((p, i) => {
                    let address = `${p.addressPrimaryStreet}${p.addressPrimarySuite ? ` ${p.addressPrimarySuite} ` : ''}${p.addressPrimaryCity} ${p.addressPrimaryState} ${p.addressPrimaryZip}`;
                    return <option key={i} value={p.id}>{address}</option>
                })}
                </select>
            </div>
          </div>
        );
    };

    if (fieldName === 'skillLocation') {
        return (
          <div className="field pt-1" key={fieldName}>
            <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
            <div className="select colorLightBlue">
                <select
                    className='formInput inputLight is-size-6'
                    {...commonProps}
                    onChange={handleInputChange}
                >
                    <option value="">Select course location...</option>
                    <option value="in-person">In-person</option>
                    <option value="online">Online</option>
                    <option value="hybrid">Hybrid</option>
                </select>
            </div>
          </div>
        );
    };

    if (fieldName === 'skillDescription' || fieldName === 'skillOutcomes') {
        return (
          <div className="pt-1" key={fieldName}>
            <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
            <textarea
                className='is-size-6 textarea-fixed-height colorLightBlue'
                {...commonProps}
                placeholder={fieldPlaceholder[fieldName]}
                onChange={handleInputChange}
                >
            </textarea>
          </div>
        );
    };

    if (fieldName === 'skillStartDate' || fieldName === 'skillEndDate') {
        return (
            <div className="field pt-1" key={fieldName}>
                <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
                    <div className="control">
                        <input
                            className="input is-size-6 formInput inputLight" 
                            type="date"
                            {...commonProps}
                            placeholder={fieldPlaceholder[fieldName]} 
                            onChange={handleInputChange}
                            onKeyDown={onKeyDownPrevent}
                        />
                    </div>
            </div>
        );
    };

    if (fieldName === 'skillInstructorPhoto') {
        return (
            <div className="file is-normal has-name is-fullwidth pt-1" key={fieldName}>
                <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}
                    <div className="file-label is-flex formInput is-fullwidth">
                        <input 
                            className="buttonHeight input file-input is-fullwidth is-size-6 inputLight" 
                            type="file" 
                            {...commonProps}
                            onChange={handleInputChange}
                        />
                        <span className="file-cta is-flex align-items-center justify-content-center buttonHeight">
                            <span className="file-icon">
                                <i className="fas fa-upload"></i>
                            </span>
                            <span>Choose a file… </span>
                        </span>
                        <span className="file-name is-size-6 is-flex align-items-center justify-content-center buttonHeight">Min 192px square.</span>
                    </div>
                </label>
            </div>
        );
    };

    return (
        <div className="field pt-1" key={fieldName}>
            <label className="label m-1 has-text-black" htmlFor={fieldName}>{fieldLabels[fieldName]}</label>
                <div className="control">
                    <input
                        className="input is-size-6 formInput inputLight"
                        type="text"
                        {...commonProps}
                        placeholder= {fieldPlaceholder[fieldName]}
                        onChange={handleInputChange}
                        onKeyDown={onKeyDownPrevent}
                    />
                </div>
        </div>
        );
    };

    return (
    <section className="theme-light section m-0 p-0 backgroundWhiteColor" data-theme="light">
        <div className="container backgroundWhiteColor">
            <div className="backgroundWhiteColor fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                <div>
                    <NavPrivate />
                    <div className="theme-light is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 pt-6">
                        <div className="column is-12-mobile is-10-tablet is-8-desktop m-0 p-0">
                            <div>
                                <div className="m-0 p-0 is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                                    <>
                                    <form onSubmit={handleSubmit}>
                                        <div className="is-size-4 my-4 has-text-black is-flex is-flex-direction-column is-justify-content-start is-align-items-center">
                                            <h1 className="has-text-weight-bold">{isEdit ? 'Edit' : 'Create'} <span className='has-text-weight-normal'>Skills Training</span></h1>
                                            <p className='is-size-7 colorLightRed my-3 pb-2'>* Indicates required field</p>
                                        </div>
                                        <div className="field">
                                            <label htmlFor="options" className="label m-1 has-text-black">Provider (school) *</label>
                                            <div className="select">
                                                <select id="options" 
                                                    className="formInput inputLight is-size-6"
                                                    value={selectedOption}
                                                    onChange={handleSelectChange}
                                                    disabled={isEdit}
                                                >
                                                    <option value="">Select a training provider...</option>
                                                    {/* <option value="school1">Clausen House</option>
                                                    <option value="school2">Laney College</option>
                                                    <option value="school3">Merritt College</option>
                                                    <option value="school4">Oakland Unified School District</option> */}
                                                    {providers.map((e, i) => {
                                                        return <option key={i} value={e.id}>{e.nameProviderLong}</option>;
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        
                                        {getFieldOrder().map((field, index) => renderField(field))}

                                        {selectedOption && (
                                        <>
                                        <div className="field pt-4 mb-6 pb-6 is-grouped is-flex is-justify-content-flex-end is-full">
                                            <div>
                                                <button 
                                                    type="submit"    
                                                    id="submit" 
                                                    className={`button is-size-6 buttonLight ${isLoading ? 'is-loading' : ''}`}
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? 'Loading...' : 'Submit'}
                                                </button>
                                            </div>
                                        </div>
                                        </>
                                        )}
                                    </form>
                                    </>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                {showModal && 
                    <JobPostingModal 
                        alertId="SE001"
                        alertHeading={alertHeading}
                        alertAction={alertAction}
                        alertMessage={alertMessage}
                        alertSubmit={modalAction}
                        close={() => {setShowModal(false)}}
                    />
                }
                <Footer /> 
            </div>
        </div>
    </section>
  );    
}

export default CreateSkillForm;
