import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import NavPrivate from './NavPrivate';
import ContactHelpLink from './ContactHelpLink';
import Avatar from './Avatar';
import CopyLink from './CopyLink';
import ProfileSocialLinks from './ProfileSocialLinks';
import ProfileContactInfo from './ProfileContactInfo';
import ProfileInfo from './ProfileInfo';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import JobPostingToast from './ConfirmationToast';
import JobPostingModal from './ConfirmationModal';
import CopyLinkButton from './CopyLinkButton';
import { CheckSession } from './Auth-Api';
import ProfileContentClient from './ProfileContentClient';
import ProfileBioInfo from './ProfileBioInfo';
import ProfileSupportiveEmployment from './ProfileSupportiveEmployment';

const ContactProfile = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [toastMessage, setToastMessage] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [alertHeading, setAlertHeading] = useState('');
    const [alertAction, setAlertAction] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [modalAction, setModalAction] = useState(null);

    const [certifications, setCertifications] = useState([]);
    const [skills, setSkills] = useState([]);
    const [documents, setDocuments] = useState([]);

    const [user, setUser] = useState({});

    CheckSession();
    const navigate = useNavigate();

    const handleArchive = () => {
        setShowModal(true);
        setModalAction(() => archivesubmit);
        setAlertHeading('Confirm');
        setAlertAction('Archive Contact');
        setAlertMessage('Are you sure you want to archive this job posting? This is not reversable.');
    };
    
    const archivesubmit = () => {
        const payload = {
            id: user.id,
            active: false,
        }
        axios.put(`/api/user/${user.id}`, payload)
        .then(res => {
            // refresh page?
            console.log(res)
            navigate(`/contacts?showToast=Contact Archived`);
        })
        .catch(err => {
            console.log(err)
        });
    }

    useEffect(() => {
        const id = searchParams.get('id');

        const toast = searchParams.get('showToast');
        if (toast) {
            setToastMessage(toast);
        }

        axios.get(`/api/user/${id}`)
        .then(res => {
            console.log('user res', res.data.data[0])
            const u = res?.data?.data[0];
            if (u) {
                if (u.avatar) {
                    axios.get(`/api/user/avatar/${u.id}?key=${u.avatar}`)
                    .then(res => {
                        u.avatar = res.data?.url;
                        setUser(u);
                    })
                    .catch(err => {
                        console.log('error avatar', err)
                        setUser(u);
                    });
                } else {
                    setUser(u);
                }
            } else {
                navigate('/contacts?showToast=Contact not found');
            }
        })
        .catch(err => {
            console.log('err', err);
            navigate('/contacts?showToast=Contact not found');
        });

        axios.get(`/api/clientCertifications?page=1&limit=100&sort=year&sortOrder=-1&clientId=${id}`)
        .then(res => {
            setCertifications(res.data?.data || []);
        })
        .catch(err => {
            console.log('err', err);
        });

        axios.get(`/api/clientSkills?page=1&limit=100&sort=completionYear&sortOrder=-1&clientId=${id}`)
        .then(res => {
            setSkills(res.data?.data || []);
        })
        .catch(err => {
            console.log('err', err);
        });

        axios.get(`/api/clientDocuments?page=1&limit=100&sort=createdOn&sortOrder=-1&clientId=${id}`)
        .then(res => {
            console.log(res.data.data);
            setDocuments(res.data?.data || []);
        })
        .catch(err => {
            console.log('err', err);
        });
    }, []);
    
    return (
        <>
        <section className='theme-light fullHeight is-flex is-flex-direction-column is-justify-content-space-evenly' data-theme="light">
            <div className="backgroundLightGreyColor topShield section m-0 p-0">
                <div className="container">
                    <div className="is-flex is-flex-direction-column">
                        <NavPrivate />
                        <div className="is-full is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center columns m-0 p-6">
                            <div className="oneHundredPercentWidth columns m-0 px-5">
                                <div className='m-0 p-0 column is-two-thirds descriptionContent is-flex is-flex-direction-rows is-align-items-center is-justify-content-start'>
                                    {user.role !== 'manager' ? (
                                            <div className='avatar avatarWidth mx-2'>
                                                <Avatar imageUrl={user.avatar} initials={user?.firstName ? `${user.firstName[0]}${user.lastName[0]}` : ''} size={user?.size || 244}  />
                                            </div>
                                        ) : (
                                            <div className='mx-2'></div>
                                        )
                                        
                                        }
                                    <div className='mx-4 p-5 is-flex is-flex-wrap-wrap is-flex-direction-column is-align-content-center is-justify-content-start'>
                                       <ProfileInfo {...user}/>
                                    </div>
                                </div>
                                <div className='m-0 p-0 column contactLink is-flex is-flex-direction-column is-flex-wrap-wrap is-justify-content-space-between is-align-items-end'>
                                    <ContactHelpLink />
                                    <div className='linkContactEditArchive is-flex is-flex-direction-rows is-justify-content-center is-align-items-end'>
                                        <p className='py-2 m-0'><a href={`/#/create-contact?id=${user.id}`} role="button" className="noUnderline button buttonLight buttonMedium">Edit profile</a></p>
                                        <p className='py-2 pl-4 m-0'><a onClick={handleArchive} role="button" className="noUnderline button buttonLight buttonMedium">Archive profile</a></p>
                                    </div>
                                </div>
                            </div>                                    
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="is-full is-flex is-flex-direction-column columns m-0 p-6 ">
                    <div className="columns m-0 p-0">
                        <div className='m-0 px-4 column is-two-thirds is-flex is-flex-direction-column'>
                            {user.role !== 'manager' ? (
                            <>
                                <ProfileBioInfo skills={skills} certifications={certifications} {...user} />
                            </>
                            ) : (
                                <span></span>
                            )}

                            {user.role === 'client' ? (
                                <ProfileContentClient 
                                id={user.id}
                                documents={documents}
                                clientInterviewDate={user.clientInterviewDate}
                                clientInterviewer={user.clientInterviewer}
                                clientCaregiver={user.clientCaregiver}
                                clientCaregiverContact={user.clientCaregiverContact}
                                clientDOB={user.clientDOB}
                                clientCaregiverRelationship={user.clientCaregiverRelationship}
                                clientConservatorship={user.clientAddressCdlId}
                                clientConservatorName={user.clientAddressCdlId}
                                clientConservatorPhone={user.clientAddressCdlId}
                                clientConservatorEmail={user.clientAddressCdlId}
                                clientAddressCdlId={user.clientAddressCdlId}
                                clientSSN={user.clientSSN}
                                clientAddressUci={user.clientAddressUci}
                                clientLivingSituation={user.clientLivingSituation}
                                clientAddressStreet={user.clientAddressStreet}
                                clientAddressCity={user.clientAddressCity}
                                clientAddressState={user.clientAddressState}
                                clientAddressZip={user.clientAddressZip}
                                clientTransportation={user.clientTransportation}
                                clientIncome={user.clientIncome}
                                clientPayee={user.clientPayee}
                                referralDetails={user.referralDetails}
                                caseManagerDetails={user.caseManagerDetails}
                                createdOn={user.createdOn}
                              />
                            ) : (
                                <span></span>   
                            )} 
                        </div>
                        <div className='column px-4 m-0'>
                            <div className='is-flex is-flex-direction-column'>
                                <CopyLinkButton url="https://clausencareers.org/#/profile?id=15440d67-ef3c-42d1-9817-87b0183e1f0c" />
                                
                                <ProfileContactInfo {...user} />
                                
                                {user.role === 'client' ? (
                                    <>

                                    <ProfileSupportiveEmployment 
                                        clientCurrentStep={user.clientCurrentStep}
                                    />
                                    </>
                                ) : (
                                    <span></span>   
                                )} 
                                
                                {(() => {
                                const links = [
                                    user.instagramLink,
                                    user.twitterLink,
                                    user.facebookLink,
                                    user.websiteLink,
                                    user.linkedInLink
                                ];
                                const hasValidLink = links.some(link => link && link.startsWith('http'));
                                
                                return hasValidLink ? <ProfileSocialLinks {...user} /> : null;
                                })()}
                                
                            </div>
                        </div>
                    </div>                                    
                </div> 
            </div>
            {showModal && 
                <JobPostingModal 
                    alertId="SE001"
                    alertHeading={alertHeading}
                    alertAction={alertAction}
                    alertMessage={alertMessage}
                    alertSubmit={modalAction}
                    close={() => {setShowModal(false)}}
                />
            }
            {toastMessage && <JobPostingToast toastMessage={toastMessage} close={() => {setToastMessage('')}}/>}
            <div className="section is-flex is-justify-content-center m-0 p-0">
                <div className='container p-0 m-0'>
                    <Footer /> 
                </div>
            </div>
        </section>
        </>
    );
};

export default ContactProfile;