import React, { useEffect, useState } from 'react';
import NavPrivate from './NavPrivate';
import Footer  from './Footer';
import FilterSortModals from './FilterSortModals';
import ContactHelpLink from './ContactHelpLink';
import SkillsCard from './SkillsCard';
import Pagination from './Pagination';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import JobPostingToast from './ConfirmationToast';
import { CheckSession } from './Auth-Api';

const SkillsTraining = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [toastMessage, setToastMessage] = useState('');

    const [pages, setPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [skills, setSkills] = useState([]);
    const [totalSkills, setTotalSkills] = useState();
    const [sort, setSort] = useState('createdOn');
    const [sortOrder, setSortOrder] = useState(-1);
    const [active, setActive] = useState(true);
    const [filterQry, setFilterQry] = useState('');
    
    const [providers, setProviders] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState(null);

    const [filters, setFilters] = useState({
        Status: ['Active', 'Archived'],
    });
    
    CheckSession();

    const sortOptions = ['Most recent', 'Alpha A-Z', 'Alpha Z-A'];
    
    const handleFilterChange = (updatedFilters) => {
        // Handle the filter change, e.g., update search results
        console.log('Filters updated:', updatedFilters);

        if ((updatedFilters.Status.Active && updatedFilters.Status.Archived) || (!updatedFilters.Status.Active && !updatedFilters.Status.Archived)) {
            setActive(undefined);
        } else if (updatedFilters.Status.Active) {
            setActive(true);
        } else if (updatedFilters.Status.Archived) {
            setActive(false);
        }

        let filterStr = '';
        for (let i = 0; i < providers.length; i++) {
            const p = providers[i];

            if (updatedFilters.Provider && updatedFilters.Provider[p.nameProviderLong]) {
                filterStr += `&providers=${p.id}`;
            }
        }

        setFilterQry(filterStr);
        setSelectedFilters(updatedFilters);
    };

    const handleSortChange = (newSort) => {
        console.log('Sort:', newSort);
        // Update your search results based on the new sort option
        switch(newSort) {
            case 'Most recent':
                setSort('createdOn');
                setSortOrder('-1');
                setCurrentPage(1);
                break;
            case 'Alpha A-Z':
                setSort('skillTitle');
                setSortOrder('1');
                setCurrentPage(1);
                break;
            case 'Alpha Z-A':
                setSort('skillTitle');
                setSortOrder('-1');
                setCurrentPage(1);
                break;
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page)
    }

    useEffect(() => {
        const toast = searchParams.get('showToast');
        if (toast) {
            setToastMessage(toast);
        }

        axios.get(`/api/providers?page=1&limit=1000&sort=nameProviderLong&active=true`)
        .then(res => {
            const provs = res.data.data;
            const ids = {};
            const names = [];
            provs.forEach(p => {
                ids[p.nameProviderLong] = true;
                names.push(p.nameProviderLong);
            });

            setProviders(provs);
            
            setSelectedFilters({
                Provider: ids,
                Status: { 'Active': true, 'Archived': false },
            });
            setFilters({
                Provider: names,
                Status: ['Active', 'Archived']
            });
        })
        .catch(err => {
            console.log('err', err);
            alert('Error');
        });
    }, []);

    useEffect(() => {
        console.log(`/api/skills?page=${currentPage}&limit=12&sort=${sort}&sortOrder=${sortOrder}&active=${active}${filterQry}`)
        axios.get(`/api/skills?page=${currentPage}&limit=12&sort=${sort}&sortOrder=${sortOrder}&active=${active}${filterQry}`)
        .then(res => {
            setPages(res.data.metaData.totalPages);
            setCurrentPage(parseInt(res.data.metaData.pageNumber || 1));
            setTotalSkills(res.data.metaData.totalDocuments || 0);
            setSkills(res.data.data);
        })
        .catch(err => {
            // TODO JASON: handle err
            console.log('err', err);
        });
    }, [currentPage, sort, sortOrder, active, filterQry]);

    return (
        <section className="backgroundLightGreyColor theme-light section m-0 p-0" data-theme="light">
            <div className="container">
                <div className="fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                    <div>
                        <NavPrivate />
                        <div className="theme-light is-full is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 pt-6">
                            <div className="column is-12-mobile is-10-tablet is-10-desktop m-0 p-0">
                                <div>
                                    <div className="m-0 p-0">
                                        <div className="is-flex is-flex-direction-column is-align-content-flex-start my-4">
                                            <div className='is-flex is-flex-direction-row is-fullwidth is-justify-content-space-between is-align-items-center'>
                                                <h1 className="pl-4 is-size-3 has-text-weight-bold has-text-black">Courses <span className="is-size-5 has-text-weight-normal"> ({totalSkills})</span></h1>
                                                <div className='m-0 p-0 is-flex is-flex-direction-row is-align-items-center'>
                                                {selectedFilters && <FilterSortModals 
                                                    filters={filters} 
                                                    initialSelectedFilters={selectedFilters}
                                                    sortOptions={sortOptions}
                                                    initialSort="Most recent"
                                                    onFilterChange={handleFilterChange}
                                                    onSortChange={handleSortChange}
                                                />}
                                                    <ContactHelpLink />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='columns is-flex is-flex-direction-rows is-flex-wrap-wrap mb-4 pb-4'>
                                            {skills.map((skill, i) => {
                                                return <SkillsCard key={i} {...skill}/>
                                            })}
                                        </div>

                                        {totalSkills > 12 && (
                                            <Pagination pages={pages} currentPage={currentPage} updatePage={handlePageChange}/> 
                                        )}
                                    </div>
                                </div>
                            </div>
                               
                        </div>
                    </div>
                    {toastMessage && <JobPostingToast toastMessage={toastMessage} close={() => {setToastMessage('')}}/>}
                    <Footer /> 
                </div>
            </div>
        </section>
    )
}

export default SkillsTraining;