import React, { useEffect, useState } from 'react';
import { CheckSession } from './Auth-Api';
import NavPrivate from './NavPrivate';
import Footer  from './Footer';
import ContactHelpLink from './ContactHelpLink';
import SkillsInfo from './SkillsInfo';
import SkillsTabs from './SkillsTabs';
import CopyLink from './CopyLink';
import JobTransportation from './JobTransportation';
import AboutProvider from './AboutProvider';
import ContactPromo from './ContactPromo';
import { useNavigate, useSearchParams } from 'react-router-dom';
import JobPostingToast from './ConfirmationToast';
import JobPostingModal from './ConfirmationModal';

import axios from 'axios';

const SkillsDetail = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [toastMessage, setToastMessage] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [alertHeading, setAlertHeading] = useState('');
    const [alertAction, setAlertAction] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [modalAction, setModalAction] = useState(null);

    const [skill, setSkill] = useState({});

    CheckSession();
    const navigate = useNavigate();

    const handleArchive = () => {
        setShowModal(true);
        setModalAction(() => archivesubmit);
        setAlertHeading('Confirm');
        setAlertAction('Archive Skill');
        setAlertMessage('Are you sure you want to archive this skill? This is not reversable.');
    };
    
    const archivesubmit = () => {
        const payload = {
            skillObj: {
                id: skill.id,
                active: false,
            }
        }
        axios.put(`/api/skills/${skill.id}`, payload)
        .then(res => {
            // refresh page?
            console.log(res)
            navigate(`/skills?showToast=Skill Archived`);
        })
        .catch(err => {
            console.log(err)
        });
    };

    useEffect(() => {
        const id = searchParams.get('id');

        const toast = searchParams.get('showToast');
        if (toast) {
            setToastMessage(toast);
        }

        axios.get(`/api/skills/${id}?view=true`)
        .then(res => {
            console.log('skill res', res.data.data[0]);
            const s = res.data?.data[0];
            if (s) {
                setSkill(s);
            } else {
                navigate('/skills?showToast=Skill not found');
            }
        })
        .catch(err => {
            console.log('err', err);
            alert(err.response.data?.error || err.response?.data);
        });
    }, []);

    return (
        <section className='theme-light fullHeight is-flex is-flex-direction-column is-justify-content-space-evenly' data-theme="light">
            <div className="backgroundLightGreyColor topShield section m-0 p-0">
                <div className="container">
                    <div className="is-flex is-flex-direction-column">
                        <NavPrivate />
                        <div className="is-full is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 p-6">
                            <div className="oneHundredPercentWidth columns m-0 px-5">
                                <div className='m-0 p-0 column is-three-quarters descriptionContent is-flex is-flex-direction-rows is-align-items-center'>
                                    <div className='py-5 is-flex is-flex-wrap-wrap is-flex-direction-column is-align-content-center is-justify-content-space-between'>
                                        <SkillsInfo {...skill} />
                                    </div>
                                </div>
                                <div className='p-0 m-0 column contactLink is-flex is-flex-direction-column is-flex-wrap-wrap is-justify-content-space-between is-align-items-end'>
                                    <ContactHelpLink />
                                    <div className='is-flex is-flex-direction-rows is-justify-content-space-between is-align-items-center'>
                                        <p className='m-0 p-0'><a href={`/#/create-skill?id=${skill.id}`} role="button" className="noUnderline button buttonLight buttonMedium">Edit skill</a></p>
                                        <p className='py-2 pl-4 m-0'><a onClick={handleArchive} role="button" className="noUnderline button buttonLight buttonMedium">Archive skill</a></p>
                                    </div>
                                </div>
                            </div>                                    
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="is-full is-flex is-flex-direction-column columns m-0 p-6 ">
                    <div className="columns m-0 p-0">
                        <div className='m-0 px-4 column is-two-thirds is-flex is-flex-direction-column colorDarkGrey'>
                            <SkillsTabs {...skill} /> 
                        </div>
                        <div className='column px-4 m-0'>
                            <div className='is-flex is-flex-direction-column pb-2'>
                                <CopyLink url={skill.id} textLink="Course link" pageRef="course" />
                            </div>
                            <div className='column p-2 m-1 mb-4 cardDefault boxDiv'>
                                {/* About provider */}
                                <AboutProvider {...skill} />
                            </div>
                            <div className='column p-2 m-1 mb-4 cardDefault boxDiv'>
                                {/* Meet instructor */}
                                <ContactPromo {...skill} />
                                
                            </div>
                        </div>                             
                    </div>                                    
                </div>
            </div>
            {showModal && 
                <JobPostingModal 
                    alertId="SE001"
                    alertHeading={alertHeading}
                    alertAction={alertAction}
                    alertMessage={alertMessage}
                    alertSubmit={modalAction}
                    close={() => {setShowModal(false)}}
                />
            }
            {toastMessage && <JobPostingToast toastMessage={toastMessage} close={() => {setToastMessage('')}}/>}
            <div className="section is-flex is-justify-content-center m-0 p-0">
                <div className='container p-0 m-0'>
                    <Footer /> 
                </div>
            </div>
        </section>
    )
}

export default SkillsDetail;