import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link, useLocation } from 'react-router-dom';
import Auth from '../utils.js/Auth';
import LoginForm from '../components/Login';
import Dashboard from '../components/Dashboard';
import Privacy from '../components/Privacy';
import Terms from '../components/Terms';
import ResetPasswordForm from '../components/ResetPassword';
import CreateLoginForm from '../components/CreateLogin';
import ForgetPasswordForm from '../components/ForgetPassword';
import SendMessageForm from '../components/SendMessage';
import CreateStartForm from '../components/CreateStart';
import CreateContactForm from '../components/CreateContact';
import CreateSkillForm from '../components/CreateSkill';
import CreateJobForm from '../components/CreateJob';
import Contacts from '../components/Contacts';
import CreateEmployerForm from '../components/CreateEmployer';
import CreateReferralForm from '../components/CreateReferral';
import CreateProviderForm from '../components/CreateProvider';
import ContactProfile from '../components/ContactProfile';
import JobsBoard from '../components/JobsBoard';
import SkillsTraining from '../components/SkillsTraining';
import Settings from '../components/Settings';
import Search from '../components/Search';
import MyAccount from '../components/MyAccount';
import SkillsDetail from '../components/SkillsDetail';
import Menu from '../components/Menu';
import JobDetail from '../components/JobsDetail';
import CreateLocationForm from '../components/CreateLocation';
import CreateLocationProvider from '../components/CreateLocationProvider';
import CreateLocationReferral from '../components/CreateLocationReferral';
import UpdatePasswordForm from '../components/UpdatePasswordForm';
import AddCertificationForm from '../components/AddCertificationForm';
import AddSkillForm from '../components/AddSkillForm';
import AddDocumentForm from '../components/AddDocumentForm';

const Routers = () => {
    
    const location = useLocation();

    React.useEffect(() => {
        // Remove all page-specific classes
        document.documentElement.classList.remove('backgroundWhiteColor', 'backgroundLightGreyColor');
        
        // Add the appropriate class based on the current path
        switch(location.pathname) {
            case '/skills':
            case '/contacts':
            case '/jobs':
            case '/dashboard': 
            case '/':           
                document.documentElement.classList.add('backgroundLightGreyColor');
                break;
            default:
                // You could add a default class here if needed
                document.documentElement.classList.add('backgroundWhiteColor');
                break;
        }
    }, [location]);

    return (
        <Routes>
            <Route path="/" element={
                <PublicRoute>
                    <LoginForm />
                </PublicRoute>
            } />
            <Route path="/login" element={
                <PublicRoute>
                    <LoginForm />
                </PublicRoute>
            } />
            <Route path="/privacy" element={
                // <PublicRoute>
                    <Privacy />
                // </PublicRoute>
            } />
            <Route path="/terms" element={
                // <PublicRoute>
                    <Terms />
                // </PublicRoute>
            } />
            <Route path="/forget-password" element={
                // <PublicRoute>
                    <ForgetPasswordForm />
                // </PublicRoute>
            } />
            <Route path="/create-login" element={
                // <PublicRoute>
                    <CreateLoginForm />
                // </PublicRoute>
            } />
            <Route path="/reset-password" element={
                // <PublicRoute>
                    <ResetPasswordForm />
                // </PublicRoute>
            } />
            <Route path="/update-password" element={
                <PrivateRoute>
                    <UpdatePasswordForm />
                </PrivateRoute>
            } />
            <Route path="/add-certification" element={
                // <PublicRoute>
                    <AddCertificationForm />
                // </PublicRoute>
            } />
            <Route path="/add-skill" element={
                // <PublicRoute>
                    <AddSkillForm />
                // </PublicRoute>
            } />
            <Route path="/add-document" element={
                // <PublicRoute>
                    <AddDocumentForm />
                // </PublicRoute>
            } />
            <Route path="/message" element={
                // <PublicRoute>
                    <SendMessageForm />
                // </PublicRoute>
            } />
            <Route path="/create" element={
                <PrivateRoute>
                    <CreateStartForm />
                </PrivateRoute>
            } />
            <Route path="/create-skill" element={
                <PrivateRoute>
                    <CreateSkillForm />
                </PrivateRoute>
            } />
            <Route path="/create-job" element={
                <PrivateRoute>
                    <CreateJobForm />
                </PrivateRoute>
            } />
            <Route path="/create-employer" element={
                <PrivateRoute>
                    <CreateEmployerForm />
                </PrivateRoute>
            } />
            <Route path="/create-location" element={
                <PrivateRoute>
                    <CreateLocationForm />
                </PrivateRoute>
            } />
            <Route path="/create-referral" element={
                <PrivateRoute>
                    <CreateReferralForm />
                </PrivateRoute>
            } />
            <Route path="/create-location-referral" element={
                <PrivateRoute>
                    <CreateLocationReferral />
                </PrivateRoute>
            } />
            <Route path="/create-provider" element={
                <PrivateRoute>
                    <CreateProviderForm />
                </PrivateRoute>
            } />
            <Route path="/create-location-provider" element={
                <PrivateRoute>
                    <CreateLocationProvider />
                </PrivateRoute>
            } />
            <Route path="/create-contact" element={
                <PrivateRoute>
                    <CreateContactForm />
                </PrivateRoute>
            } />
            {/* extra route? */}
            {/* <Route path="/create-skill" element={
                <PrivateRoute>
                    <CreateSkillForm />
                </PrivateRoute>
            } /> */}
            <Route path="/dashboard" element={
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute>
            } />
            <Route path="/contacts" element={
                <PrivateRoute>
                    <Contacts />
                </PrivateRoute>
            } />
            <Route path="/profile" element={
                <PrivateRoute>
                    <ContactProfile />
                </PrivateRoute>
            } />
            <Route path="/jobs" element={
                <PrivateRoute>
                    <JobsBoard />
                </PrivateRoute>
            } />
            <Route path="/skills" element={
                <PrivateRoute>
                    <SkillsTraining />
                </PrivateRoute>
            } />
            <Route path="/course" element={
                <PrivateRoute>
                    <SkillsDetail />
                </PrivateRoute>
            } />
            <Route path="/settings" element={
                <PrivateRoute>
                    <Settings />
                </PrivateRoute>
            } />
            <Route path="/search" element={
                <PrivateRoute>
                    <Search />
                </PrivateRoute>
            } />
            <Route path="/account" element={
                <PrivateRoute>
                    <MyAccount />
                </PrivateRoute>
            } />
            <Route path="/menu" element={
                <PrivateRoute>
                    <Menu />
                </PrivateRoute>
            } />
            <Route path="/job" element={
                <PrivateRoute>
                    <JobDetail />
                </PrivateRoute>
            } />
        </Routes>
    )
}

function PublicRoute({ children }) {
    const auth = React.useContext(Auth);
    // console.log('public', auth)
    return !auth.auth ? <>{children}</> : <Navigate to="/dashboard" />;
}
    
function PrivateRoute({ children }) {
    const auth = React.useContext(Auth);
    // console.log('private', auth, children)
    return auth.auth ? <>{children}</> : <Navigate to="/" />;
}

export default Routers;