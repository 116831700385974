import React from "react";
import TagDisplay from "./TagDisplay";

const JobInfo = ({ 
    employerDetails,
    employerName,
    jobTitle,
    jobTags,
}) => {

    return (
        <>
        <p className='m-0 p-0 is-size-7 has-text-weight-bold colorLightGreen'>{employerDetails?.nameEmployer}</p>
        <h1 className="mb-1 p-0 is-size-3 has-text-weight-bold colorDarkGrey">{jobTitle}</h1>
        <p className='mb-2 pt-4 is-size-5'><TagDisplay TagsContent={jobTags} /></p>
        </>
    );
};

export default JobInfo;



