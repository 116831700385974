import React, { useState, useContext } from 'react';
import Footer from './Footer';
import NavPublic from './NavPublic';
import axios from 'axios';


const SendMessageForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [contactMessage, setContactMessage] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!firstName || !lastName || !username || !contactMessage) {
        setMessage('All fields required');
        return;
    }

    const payload = {
        firstName,
        lastName,
        username,
        message: contactMessage,
    }

    setIsLoading(true);
    axios.post('/api/publicMessage', payload)
        .then(res => {
            setIsLoading(false);
            setFirstName('');
            setLastName('');
            setUsername('');
            setContactMessage('');
            setMessage('Message sent');
        })
        .catch(err => {
            setIsLoading(false);
            console.log(err);
            setMessage('Error');
        });
  }

  return (
    <section className="theme-light section m-0 p-0 backgroundWhiteColor" data-theme="light">
        <div className="container">
            <div className="fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                <div>
                    <NavPublic />
                    <div className="theme-light is-flex is-flex-direction-column is-justify-content-start columns is-vcentered m-0 p-0">
                        <div className="column is-6 m-0 p-0">
                            <div>
                                <div className="m-4 p-4">
                                    <form>
                                        <div className="is-size-4 my-4 has-text-black">
                                            <h1 className="has-text-weight-bold">We would love to hear from you.</h1>
                                            <p className='is-size-6 my-3 pb-2'>Whether you are looking for a login, have a support issue, or are a member of the press, we're ready to answer any and all questions.</p>
                                        </div>
                                        <div className="field">
                                            <label htmlFor="firstName" className="label m-1 has-text-black">First name</label>
                                            <div className="control has-icons-right">
                                                <input 
                                                    id="firstName" 
                                                    className="input inputLight" 
                                                    autoComplete="given-name" 
                                                    type="text" 
                                                    placeholder="Enter first name" 
                                                    value={firstName} 
                                                    onChange={(e) => setFirstName(e.target.value)}
                                    
                                                />
                                                <span className="icon is-small is-right">
                                                    <i className="fas fa-check"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="field pt-1">
                                            <label htmlFor="lastName" className="label m-1 has-text-black">Last name</label>
                                            <div className="control has-icons-right">
                                                <input 
                                                    id="plastName" 
                                                    className="input inputLight" 
                                                    autoComplete="family-name" 
                                                    type="text" 
                                                    placeholder="Enter last name"
                                                    value={lastName} 
                                                    // onChange={handlePasswordChange}
                                                    onChange={(e) => setLastName(e.target.value)} 
                                                />
                                                <span className="icon is-small is-right">
                                                    <i className="fas fa-check"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="field pt-1">
                                            <label htmlFor="username" className="label m-1 has-text-black">Email address</label>
                                            <div className="control has-icons-right">
                                                <input 
                                                    id="username" 
                                                    className="input inputLight" 
                                                    autoComplete="email" 
                                                    type="email" 
                                                    placeholder="Enter email address"
                                                    value={username} 
                                                    onChange={(e) => setUsername(e.target.value)} 
                                                />
                                                <span className="icon is-small is-right">
                                                    <i className="fas fa-check"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="field pt-1">
                                            <label htmlFor="contactMessage" className="label m-1 has-text-black">Message</label>
                                            <div className="control has-icons-right">
                                                <textarea
                                                    id="contactMessage" 
                                                    className="textarea inputLight" 
                                                    autoComplete="on" 
                                                    placeholder="Type your message..."
                                                    value={contactMessage} 
                                                    onChange={(e) => setContactMessage(e.target.value)} 
                                                />
                                            </div>
                                            <p className="help file-name colorLightRed has-text-weight-bold">All fields are required.</p>
                                            <p>{message}</p>
                                        </div>
                                        <div className="field mb-6 pb-6 is-grouped is-flex is-justify-content-flex-end is-full">
                                            <div>
                                                <button 
                                                    id="submit" 
                                                    className={`button is-size-6 buttonLight ${isLoading ? 'is-loading' : ''}`}
                                                    disabled={isLoading}
                                                    onClick={handleSubmit}
                                                >
                                                    {isLoading ? 'Loading...' : 'Submit'}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                <Footer /> 
            </div>
        </div>
    </section>
  );    
}

export default SendMessageForm;
