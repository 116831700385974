import React, { useState } from 'react';
import BulletedListDisplay from './BulletedListDisplay';

const JobTabs = ({ 
    jobDescription,
    jobResponsibilities,
    jobBenefits,
    jobDetails,
    jobHours,
    jobPay,
    bulletContent,
    jobDisclaimer
}) => {
  
  const [activeTab, setActiveTab] = useState('description');

  const tabs = [
    { id: 'description', label: 'Description' },
    { id: 'details', label: 'Details' },
  ];

  const renderDescriptionInfo = () => (
    <div>
      <p className='colorDarkGrey has-text-weight-normal mb-4 is-size-6'>{jobDescription}</p>
      <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Responsibilities</p>
      <p className='colorDarkGrey has-text-weight-normal mb-4 is-size-6'><BulletedListDisplay bulletContent={jobResponsibilities} /></p>
      <>
      {jobBenefits && (
        <>
          <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Benefits</p>
          <p className='colorDarkGrey is-size-6 mb-4'><BulletedListDisplay bulletContent={jobBenefits} /></p>
        </>
      )}
      </>
    </div>
  );

  const renderDetailsInfo = () => (
    <div>
      
      {jobDetails && (
        <>
        <p className='colorDarkGrey is-size-6 mb-4'>{jobDetails}</p>
        </>
      )}
    
      {jobHours && (
        <>
        <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Hours</p>
        <p className='colorDarkGrey is-size-6 mb-4'>{jobHours}</p>
        </>
      )}
      
      {jobPay && (
        <>
        <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Pay</p>
        <p className='colorDarkGrey is-size-6 mb-4'>{jobPay}</p>
        </>
      )}

      {jobDisclaimer && (
        <>
        <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Disclaimer</p>
        <p className='colorDarkGrey has-text-weight-normal mb-4 is-size-6'>{jobDisclaimer}</p>
        </>
      )}
    </div>
  );

  return (
    <div className="container">
        <div className="tabs-wrapper tabsHeight is-flex is-flex-direction-column">
            <div className="tabs is-size-6 is-boxed m-0 p-0">
                <ul className="tabsUl">
                    {tabs.map((tab) => (
                        <li
                        key={tab.id}
                        className={activeTab === tab.id ? 'is-active' : ''}
                        onClick={() => setActiveTab(tab.id)}
                        >
                        <a>{tab.label}</a>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="tabsContent">
                {activeTab === 'description' && renderDescriptionInfo()}
                {activeTab === 'details' && renderDetailsInfo()}
            </div>
        </div>
    </div>
  );
};

export default JobTabs;