import React, { useEffect, useState } from 'react';
import PasswordDisplay from './PasswordDisplay';
import TextareaFormat from './TextareaFormat';
import ConditionalComponent from './ConditionalComponent';
import axios from 'axios';

const ProfileBioInfo = ({ 
    id,
    skills,
    certifications,
    role,
    bio,
    email, 
    employer, 
    clientAddressCity, 
    clientEducation, 
    clientDOB,
    clientLanguages,  
    password,
    active, 
}) => {

  return (
    <>
        {/* <ConditionalComponent 
            variable1={bio} 
            variable2={clientEducation} 
            variable3={clientLanguages}
            variable4={employer} 
        /> */}
        <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Bio</p>
        {bio ? (
        <>
            <p className='colorDarkGrey has-text-weight-normal is-size-6 mb-4'>{bio}</p>
        </>
        ) : (
            <p className='colorDarkGrey has-text-weight-normal is-size-6 mb-4'>Houston, we have a problem: there is no bio, over.</p>
        )}
        {employer ? (
        <>
            <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Employer</p>
            <p className='colorDarkGrey has-text-weight-normal mb-4 is-size-6'>{employer}</p>
        </>
        ) : (
        <p></p>
        )}
        {clientEducation ? (
        <>
            <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Education</p>
            <p className='colorDarkGrey has-text-weight-normal mb-2 is-size-6'><TextareaFormat text={clientEducation} /></p>
        </>
            ) : (
            <p></p>
        )}
        {clientLanguages ? (
        <>
            <p className='colorLightGreen has-text-weight-bold mb-0 is-size-6'>Languages</p>
            <p className='colorDarkGrey has-text-weight-normal mb-4 is-size-6'>{clientLanguages}</p>
        </>
        ) : (
            <p></p>
        )}
        {role === 'client' ? (
            <>
            {/* Insert certifications here */}
            <div className='pt-6 is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <h2 className='pl-4 is-size-5 has-text-weight-bold colorDarkGrey'>Certifications</h2>
                <div className='pr-4 is-size-6'><a href={`/#/add-certification?id=${id}`}>Add certifications</a></div>
            </div>
            <div className=''>
                {certifications && certifications.map((c, i) => {
                    return (
                        <div key={i} className='boxDivGrey oneHundredPercentWidth p-4 mx-0 my-2 is-flex is-flex-direction-rows is-justify-content-space-between is-align-items-center'>
                            <div><p className='is-size-6 has-text-weight-bold colorBlack'>{c.programName}</p></div>
                            <div><p className='is-size-6 has-text-weight-normal colorBlack'>{c.year}</p></div>
                            {/* <div><p className='is-size-6 has-text-weight-normal colorBlack'><span className='is-size-7 has-text-normal mr-4'>Laney College, Oakland, CA</span> {c.year}</p></div> */}
                        </div>
                    )
                })}
            </div>

            {/* Insert skills here */}
            <div className='pt-4 is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <h2 className='pl-4 is-size-5 has-text-weight-bold colorDarkGrey'>Skills</h2>
                <div className='pr-4 is-size-6'><a href={`/#/add-skill?id=${id}`}>Add skill</a></div>
            </div>
            <div className=''>
                {skills && skills.map((s, i) => {
                    return (
                        <div key={i} className='boxDivGrey oneHundredPercentWidth p-4 mx-0 my-2 is-flex is-flex-direction-rows is-justify-content-space-between is-align-items-center'>
                            <div><p className='is-size-6 has-text-weight-bold colorBlack'>{s.skillDetails.skillTitle}</p></div>
                            <div><p className='is-size-6 has-text-weight-normal colorBlack'>{s.completionYear}</p></div>
                            {/* <div><p className='is-size-6 has-text-weight-normal colorBlack'><span className='is-size-7 has-text-normal mr-4'>Laney College, Oakland, CA</span> {s.year}</p></div> */}
                        </div>
                    )
                })}
            </div>
            </>
        ) : (
            <span></span>
        )}
    <div className='pt-6'>
        <h2 className='pl-4 is-size-5 has-text-weight-bold colorDarkGrey'>Login</h2>
    </div>
    <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
        <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
            <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Status</p></div>
            <div className=''>
                {active ? (
                    <>
                    <p className='colorDarkGrey has-text-weight-normal is-size-6'>The account is active.</p>
                    </>
                    ) : (
                    <p className='colorDarkGrey has-text-weight-normal is-size-6'>The account is archived.</p>
                )}
            </div>
        </div>
    </div>
    <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
        <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
            <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Username</p></div>
            <div className=''>
                <p className='colorDarkGrey is-size-6'>{email}</p>
            </div>
        </div>
    </div>
    <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
        <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
            <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Password</p></div>
            <div className=''>
                {password ? (
                    <>
                    <p className='colorDarkGrey has-text-weight-normal is-size-6'>Password created.<span className='colorLightGreen has-text-weight-bold ml-2 mb-5 is-size-7'><a href={`#/update-password?id=${id}`}>Update password</a></span></p>
                    </>
                ) : (
                    <>
                    <p className='colorDarkGrey is-size-6'>No password saved.<span className='colorLightGreen has-text-weight-bold ml-2 mb-5 is-size-7'><a href={`#/update-password?id=${id}`}>Create new password</a></span></p>
                    </>
                )}
            </div>
        </div>
    </div>
    </>
  );
};

export default ProfileBioInfo;