import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import NavPrivate from './NavPrivate';
import ContactHelpLink from './ContactHelpLink';
import JobInfo from './JobInfo';
import JobTabs from './JobTabs';
import CopyLink from './CopyLink';
import JobMap from './JobMap';
import JobTransportation from './JobTransportation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import JobPostingToast from './ConfirmationToast';
import JobPostingModal from './ConfirmationModal';

const JobsDetail = () => {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [toastMessage, setToastMessage] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [alertHeading, setAlertHeading] = useState('');
    const [alertAction, setAlertAction] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [modalAction, setModalAction] = useState(null);

    const [job, setJob] = useState({});
    
    const jobProfile = {
        employerName: "Safeway",
        jobTitle: "Produce Clerk",
        jobDescription: "Safeway Alameda in the South Shore Center is seeking a dedicated Produce Clerk to join our team. The ideal candidate will be responsible for stocking and maintaining the produce section, assisting customers, and ensuring the quality of products.",
        jobTags: "Full time, Near public transportation, Needed weekends, Benefits available",
        jobResponsibilities: "Package and stock and rotate products in the produce department, Use pallet jacks or forklifts to move produce shipments, Communicate with customers to provide assistance and sell products, Maintain cleanliness and organization of the produce area, Ensure all products meet quality standards",
        jobBenefits: "401(k), 401(k) matching, Dental insurance, Employee assistance program, Employee discount, Flexible schedule, Flexible spending account, Health insurance, Paid time off, Paid training, Retirement plan, Vision insurance",
        jobDetails: "",
        jobHours: "Monday - Friday 9 AM - 5 PM",
        jobPay: "$19.00 - $26.50 per hour",
        jobDisclaimer: "Safeway is an equal opportunity employer. We celebrate diversity and are committed to creating an inclusive environment for all employees. All qualified applicants will receive consideration for employment without regard to race, color, religion, sex, sexual orientation, gender identity, national origin, disability, age, or protected veteran status. We encourage individuals from all backgrounds to apply.",
    };
    
    const employerInfo = {
        employerName: "Safeway",
        selectLocation: "2227 S Shore Center, Alameda, CA 94501",
        transportation: "BART: Exit the Fruitvale Station and transfer to AC Transit 20 bus line. Bus: AC Transit 20 line stops right in front of the South Shore Center.", 
        locationLat: "37.7572555",
        locationLong: "-122.2508336",
    };

    const locationInfo = {
        emailAddresses: [
            "anthony@42inc.com", 
        ],
        phoneNumbers: [
            { type: "Main", number: "(123) 456-7890" },
            { type: "Fax", number: "(098) 765-4321" },
        ],
        webAddresses: [
            { type: "Web", url: "safeway.com?location=123" },
            { type: "Contact", url: "safeway.com/support" },
        ],
    };

    const handleArchive = () => {
        setShowModal(true);
        setModalAction(() => archivesubmit);
        setAlertHeading('Confirm');
        setAlertAction('Archive Job');
        setAlertMessage('Are you sure you want to archive this job? This is not reversable.');
    };
    
    const archivesubmit = () => {
        const payload = {
            jobObj: {
                id: job.id,
                active: false,
            }
        }
        axios.put(`/api/jobs/${job.id}`, payload)
        .then(res => {
            // refresh page?
            console.log(res)
            navigate(`/jobs?showToast=Job archived`);
        })
        .catch(err => {
            console.log(err)
        });
    }

    useEffect(() => {
        const id = searchParams.get('id');

        const toast = searchParams.get('showToast');
        if (toast) {
            setToastMessage(toast);
        }

        axios.get(`/api/jobs/${id}?view=true`)
        .then(res => {
            console.log('job res', res.data);
            const j = res.data?.data[0];
            if (j) {
                setJob(j);
            } else {
                navigate('/jobs?showToast=Job not found');
            }
        })
        .catch(err => {
            console.log('err', err);
            alert(err.response.data?.error || err.response?.data);
        });
    }, []);

    return (
        <>
        <section className='theme-light fullHeight is-flex is-flex-direction-column is-justify-content-space-evenly' data-theme="light">
            <div className="backgroundLightGreyColor topShield section m-0 p-0">
                <div className="container">
                    <div className="is-flex is-flex-direction-column">
                        <NavPrivate />
                        <div className="is-full is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 p-6">
                            <div className="oneHundredPercentWidth columns m-0 px-5">
                                <div className='m-0 p-0 column is-three-quarters descriptionContent is-flex is-flex-direction-rows is-align-items-center'>
                                    <div className='py-5 is-flex is-flex-wrap-wrap is-flex-direction-column is-align-content-center is-justify-content-space-between'>
                                        <JobInfo {...job} />
                                    </div>
                                </div>
                                <div className='p-0 m-0 column contactLink is-flex is-flex-direction-column is-flex-wrap-wrap is-justify-content-space-between is-align-items-end'>
                                    <ContactHelpLink />
                                    <div className='is-flex is-flex-direction-rows is-justify-content-space-between is-align-items-center'>
                                        <p className='m-0 p-0'><a href={`/#/create-job?id=${job.id}`} role="button" className="noUnderline button buttonLight buttonMedium">Edit job</a></p>
                                        <p className='py-2 pl-4 m-0'><a onClick={handleArchive} role="button" className="noUnderline button buttonLight buttonMedium">Archive job</a></p>
                                    </div>
                                </div>
                            </div>                                    
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="is-full is-flex is-flex-direction-column columns m-0 p-6 ">
                    <div className="columns m-0 p-0">
                        <div className='m-0 px-4 column is-two-thirds is-flex is-flex-direction-column colorDarkGrey'>
                            <JobTabs {...job} /> 
                        </div>
                        <div className='column px-4 m-0'>
                            <div className='is-flex is-flex-direction-column pb-2'>
                                <CopyLink url="829dsns9ns4" textLink="Job link" pageRef="job" />
                            </div>
                            <div className='column p-2 m-1 mb-4 cardDefault boxDiv halfHeight'>
                                <JobMap lat="37.7572555" lng="-122.2508336" />
                            </div>
                            <div className='column p-2 m-1 mb-4 cardDefault boxDiv'>
                                <JobTransportation {...employerInfo} />
                            </div>
                        </div>
                                               
                    </div>                                    
                </div>
            </div>
            {showModal && 
                <JobPostingModal 
                    alertId="SE001"
                    alertHeading={alertHeading}
                    alertAction={alertAction}
                    alertMessage={alertMessage}
                    alertSubmit={modalAction}
                    close={() => {setShowModal(false)}}
                />
            }
            {toastMessage && <JobPostingToast toastMessage={toastMessage} close={() => {setToastMessage('')}}/>}
            <div className="section is-flex is-justify-content-center m-0 p-0">
                <div className='container p-0 m-0'>
                    <Footer /> 
                </div>
            </div>
        </section>
        </>
    );
};

export default JobsDetail;