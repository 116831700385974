import React, { useContext } from 'react';
import Auth from '../utils.js/Auth';
import { signout } from './Auth-Api';

const LogoutTextLink = () => {
    const auth = useContext(Auth);

    const handleLogout = async () => {
        const result = await signout();
        auth.setAuth(result.data.auth);
    }

    return (
        <p className='is-hidden-mobile mx-2 px-2 is-size-6'><a onClick={handleLogout}>Log out</a></p>
    );
};

export default LogoutTextLink;



