import React, { useState, useContext } from 'react';
import Auth from '../utils.js/Auth';
import { signin } from './Auth-Api';
import Footer from './Footer';
import NavPublic from './NavPublic';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const auth = useContext(Auth);
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
        const result = await signin({username, password})

        if (result.data.auth) {
          auth.setAuth(true);
        } else {
            setMessage(result.data.response);
        }
    } catch (err) {
        setMessage('Error');
    }
  }
  return (
    <section className="theme-light section m-0 p-0 backgroundWhiteColor" data-theme="light">
        <div className="container fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
            <NavPublic />
            <div className="theme-dark is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 p-0">
                <div className="column is-6 m-0 p-0">
                    <div className="box ADA-dark">
                        <div className="m-4 p-4">
                            <form onSubmit={handleSubmit}>
                                <div className="is-size-4 my-4 has-text-white">
                                    <h1 className="has-text-weight-bold">Please log in</h1>
                                </div>
                                <div className="field">
                                    <label htmlFor="name" className="label m-1 has-text-white">Username</label>
                                    <div className="control has-icons-right">
                                        <input 
                                            id="name" 
                                            className="input inputDark" 
                                            autoComplete="email" 
                                            type="email" 
                                            placeholder="Enter email" 
                                            value={username} 
                                            onChange={(e) => setUsername(e.target.value)}
                            
                                        />
                                        <span className="icon is-small is-right">
                                            <i className="fas fa-check"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="field pt-1">
                                    <label htmlFor="pass" className="label m-1 has-text-white">Password</label>
                                    <div className="control has-icons-right">
                                        <input 
                                            id="pass" 
                                            className="input inputDark" 
                                            autoComplete="current-password" 
                                            type="password" 
                                            placeholder="Enter password"
                                            value={password} 
                                            // onChange={handlePasswordChange}
                                            onChange={(e) => setPassword(e.target.value)} 
                                        />
                                        <span className="icon is-small is-right">
                                            <i className="fas fa-check"></i>
                                        </span>
                                    </div>
                                    <p className="help file-name colorDarkRed has-text-weight-bold">All fields are required.</p>
                                    <p>{message}</p>
                                </div>
                                <div className="field is-grouped is-flex is-justify-content-flex-end is-full">
                                    <div>
                                        <button 
                                            id="submit" 
                                            className={`button is-size-6 buttonDark ${isLoading ? 'is-loading' : ''}`}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? 'Loading...' : 'Log in'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <p className="is-size-6 is-size-7-tablet is-size-7-mobile has-text-black has-text-centered mb-6 p-0"><a href="/#/forget-password" className="mx-2">Forgot your password?</a> <a href="/#/message" className="mx-2">Have a question?</a></p>
                </div>    
            </div>      
            <Footer /> 
        </div>
        
    </section>
  );    
}

export default LoginForm;
