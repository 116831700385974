import React from 'react';

const BulletedListDisplay = ({ 
  bulletContent,
 }) => {

  // Split the input string into an array of bullet points

  let bulletPoints
  if (bulletContent) {
    bulletPoints = bulletContent?.split(',').map(tag => tag.trim());
  }

  return (
    <ul className="custom-bullet-list">
      {bulletPoints && bulletPoints.map((point, index) => (
        <li key={index} className="custom-bullet-list is-size-6 has-text-weight-normal">
          {point}
        </li>
      ))}
    </ul>
  );
};

export default BulletedListDisplay;