import React from 'react';

const ContactHelpLink = () => {

return (
        <p className='is-hidden-mobile m-0 py-0 pr-4 pl-0 is-size-6'>Need help? <a href="#/message" target="_blank">Contact us</a></p>
    );
};


export default ContactHelpLink;