import React from "react";

const ContactPromo = ({ 
    skillInsructorName,
    skillInstructorBio,
    descriptionProvider,
    locationUrl,
    locationUrlTitle,
}) => {

    return (
        <>
        <div className="p-4">
            <p className='is-size-7 has-text-weight-bold colorLightGreen'>Meet the instructor</p>
            <p className='is-size-4 has-text-weight-bold colorDarkGrey pt-2'>{skillInsructorName}</p>
            <p className='is-size-6 colorDarkGrey py-1 pt-2'>{skillInstructorBio}</p>
        </div>
        </>
    );
};

export default ContactPromo;



