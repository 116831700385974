import React from "react";
import RoleBasedProfileInfo from "./RoleBasedProfileInfo";
import ConditionalComponent from './ConditionalComponent';

const ProfileInfo = ({ 
    role,
    firstName,
    lastName,
    personalStatement,
    selectSchool,
    currentJobTitle,
    selectReferral,
    selectEmployer,
    connectedClient,
    connectedCaregiver,
    useRole,
}) => {

    if (role === 'manager') {
        useRole = 'Case manager';
    } else if (role === 'provider') {
        useRole = 'Transition Provider';
    } else if (role === 'coach') {
        useRole = 'Job Coach';
    } else {
        useRole = role;
    };

    return (
        <>
        <p className='m-0 p-0 is-size-6 has-text-weight-bold colorLightGreen is-capitalized'>{useRole}</p>
        <h1 className="mb-1 p-0 is-size-3 has-text-weight-bold has-text-black">{firstName} {lastName}</h1>
        <p className='mb-2 p-0 is-size-5'>{personalStatement}</p>
        {/* 
            Client - Who is their cargiver? What school do they attend?, Who is their employer? Who is their Job Coach? Who is their Case Manager? Current job development progress?
            Caregiver - Who is their Client? Need linkage to Client profile.
            Admin - What "school" do they work for? What is their job title?
            Job Coach - What "school" do they work for? What is their job title?
            Provider - What "school" do they work for? What is their job title?
            Employer - Where do they work? What is their job title?
            Case Manager - What referral source do they work for? What is their job title?
        */}
        <RoleBasedProfileInfo selectSchool={selectSchool} selectEmployer={selectEmployer} connectedCaregiver={connectedCaregiver} connectedClient={connectedClient} currentJobTitle={currentJobTitle} selectReferral={selectReferral} role={role} />
        </>
    );
};

export default ProfileInfo;



