import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import NavPrivate from './NavPrivate';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import JobPostingModal from './ConfirmationModal';
import { CheckSession } from './Auth-Api';

const AddDocumentForm = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);

    const [user, setUser] = useState({});
    const [clientId, setClientId] = useState('');

    const [stage, setStage] = useState('');
    const [userDocument, setUserDocument] = useState(null);
    const [fileName, setFilename] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [alertHeading, setAlertHeading] = useState('');
    const [alertAction, setAlertAction] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [modalAction, setModalAction] = useState(null);

    CheckSession();
    const navigate = useNavigate();

    const handleFileChange = (event) => {
        setUserDocument({
            selectedFile: event.target.files[0]
        });
        setFilename(event.target.files[0].name);
    }

    useEffect(() => {
        const id = searchParams.get('id');
        if (!id) {
            navigate('/contacts?showToast=No Contact')
        }
        setClientId(id);

        axios.get(`/api/user/${id}`)
        .then(res => {
            const u = res?.data?.data[0];
            if (u) {
                setUser(u);
            }
        })
        .catch(err => {
            console.log('err user', err);
        });
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setShowModal(true);
        setModalAction(() => submit);
        setAlertHeading('Confirm');
        setAlertAction('Add Document');
        setAlertMessage('Are you sure you want to add this document?');
    };

    const submit = () => {
        if (!stage || !userDocument?.selectedFile || !fileName) {
            alert('Fill out required fields');
            return;
        }

        setIsLoading(true);

        const data = new FormData();
        data.append('file', userDocument.selectedFile);
        axios.post(`/api/user/document/${clientId}/${stage}?firstName=${user.firstName}&lastName=${user.lastName}`, data)
        .then(ares => {
            setIsLoading(false);
            navigate(`/profile?id=${user.id}&showToast=Document Uploaded`);
        })
        .catch(err => {
            setIsLoading(false);
            console.log(err);
        });
    };
  
    return (
        <section className="theme-light section m-0 p-0 backgroundWhiteColor" data-theme="light">
        <div className="container backgroundWhiteColor">
            <div className="backgroundWhiteColor fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                <div>
                    <NavPrivate />
                    <div className="theme-light is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 pt-6">
                        <div className="column is-10-mobile is-8-tablet is-6-desktop m-0 p-0">
                            <div>
                                <div className="m-0 p-0 is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                                    <>
                                    {/* This is new code below */}
                                    <div className="has-text-black">
                                        <div className=""></div>
                                            <form onSubmit={handleSubmit}>
                                                <div className="is-size-4 my-4 has-text-black">
                                                    <h1 className="has-text-weight-bold has-text-black">Upload a document to {user.firstName}'s profile.</h1>
                                                </div>
                                                <div className="field">
                                                    <label htmlFor="name" className="label m-1 has-text-black">Client stage</label>
                                                    <div className="select colorLightBlue">
                                                    <select
                                                        className='formInput'
                                                        onChange={(e) => {setStage(e.target.value)}}

                                                    >
                                                        <option value="">Select...</option>
                                                        <option key='intake' value='intake'>Intake</option>
                                                        <option key='orientation' value='orientation'>Orientation</option>
                                                        <option key='development' value='development'>Development</option>
                                                        <option key='exploration' value='exploration'>Exploration</option>
                                                        <option key='placement' value='placement'>Placement</option>
                                                        
                                                    </select>
                                                    </div>
                                                </div>
                                                <div className="file is-normal has-name is-fullwidth pt-4">
                                                
                                                    <label className="label has-text-black mt-1">Client stage
                                                        <div className="mt-1 file-label is-flex formInput is-fullwidth">
                                                            <input 
                                                                className="formInput buttonHeight input file-input is-size-6 inputLight" 
                                                                type="file" 
                                                                value=""
                                                                onChange={handleFileChange}
                                                            />
                                                            <span className="file-cta is-flex align-items-center justify-content-start buttonHeight">
                                                                <span>Choose a file… </span>
                                                            </span>
                                                            <span className="file-name is-size-7 is-flex align-items-center justify-content-center buttonHeight">{fileName}</span>
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="field is-grouped is-flex is-justify-content-flex-end is-full">
                                                    <div>
                                                        <button 
                                                            id="submit" 
                                                            className={`button mt-4 is-size-6 adaNo buttonLight ${isLoading ? 'is-loading' : ''}`}
                                                            disabled={isLoading}
                                                        >
                                                            {isLoading ? 'Loading...' : 'Submit'}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                    </div>
                                    {/* This is end of new code */}
                                    </>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                {showModal && 
                    <JobPostingModal 
                        alertId="SE001"
                        alertHeading={alertHeading}
                        alertAction={alertAction}
                        alertMessage={alertMessage}
                        alertSubmit={modalAction}
                        close={() => {setShowModal(false)}}
                    />
                }
                <Footer /> 
            </div>
        </div>
    </section>
  );    
}

export default AddDocumentForm;
