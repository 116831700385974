import React, { useState, useContext } from 'react';
import Footer from './Footer';
import NavPrivate from './NavPrivate';
import { CheckSession } from './Auth-Api';

const CreateStartForm = () => {
    CheckSession();
    return (
        <section className="theme-light section m-0 p-0 backgroundWhiteColor" data-theme="light">
            <div className="container">
                <div className="fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                    <div>
                        <NavPrivate />
                        <div className="theme-light is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 pt-6">
                            <div className="column is-12-mobile is-10-tablet is-8-desktop m-0 p-0">
                                <div>
                                    <div className="m-0 p-0">
                                        <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-size-4 my-4 has-text-black">
                                            <h1 className="has-text-weight-bold">What would you like to create?</h1>
                                            <p className='is-size-6 my-3 pb-2'>Please select an option below.</p>
                                        </div>
                                        <div className='is-flex is-flex-wrap-wrap is-flex-direction-rows is-justify-content-center is-align-items-center'>
                                            <a id="createJob" className="button is-size-6 m-4 adaNo buttonLight widthMedium" href="/#/create-job">Create job</a>
                                            <a id="createContact" className="button is-size-6 m-4 adaNo buttonLight widthMedium" href="/#/create-contact">Create contact</a>
                                            <a id="createSkill" className="button is-size-6 m-4 adaNo buttonLight widthMedium" href="/#/create-skill">Create skill</a>
                                            {/* <a id="createEmployer" className="button is-size-6 m-4 adaNo buttonLight widthMedium" href="/#/create-employer">Create employer</a> */}
                                            {/* <a id="createContact" className="button is-size-6 m-4 adaNo buttonLight widthMedium" href="/#/create-provider">Create provider</a> */}
                                            {/* <a id="createSkill" className="button is-size-6 m-4 adaNo buttonLight widthMedium" href="/#/create-referral">Create referral</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </div>
                    <Footer /> 
                </div>
            </div>
        </section>
    );
}

export default CreateStartForm;
