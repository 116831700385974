import React from 'react';
import Avatar from './Avatar';
import ContactActionsMenu from './ContactActionsMenu';
import TagRole from './TagRole';

import { dateHelper, timeHelper } from '../utils.js/utils';


const DashboardDataRecent = ({
    dashboardDataRecentProfiles
}) => {

    const handleClick = (contactObject) => {
        console.log(contactObject);
        const { contactUrl } = contactObject;

        // Check if contactUrl exists and is not empty
        if (contactUrl && typeof contactUrl === 'string' && contactUrl.trim() !== '') {
            // Navigate to the URL
            window.location.href = contactUrl;
        } else {
            console.error('Invalid or missing contactUrl');
        }
    };

    const isYes = (value) => value.toLowerCase() === 'yes';

    return (           
            <>
            <div className="is-2 my-2 oneHundredPercentWidth is-flex is-flex-direction-column is-justify-content-space-between dataBarHeight">    
                <table className='table is-hoverable'>
                    <thead>
                        <tr className='oneHundredPercentWidth no-hover'>
                            <th></th>
                            <th><p className='is-size-7'><abbr title="Name">Name</abbr></p></th>
                            <th className='is-hidden-mobile'><p className='is-size-7'><abbr title="Email">Role</abbr></p></th>
                            <th className='is-hidden-mobile'><p className='is-size-7'><abbr title="Phone">Updated</abbr></p></th>
                            {/* <th><p className='is-size-7'><abbr title="Role">Updated by</abbr></p></th> */}
                        </tr>
                        <>
                        {dashboardDataRecentProfiles.map((data, index) => {

                            const contactDomain = "/#/profile?id=";
                            const contactNumber = `${data.id}`;
                            const contactUrl = `${contactDomain}${contactNumber}`;

                            return (
                                <tr key={index} onClick={() => handleClick({contactUrl})} className='rowHeight is-clickable' style={{ cursor: 'pointer' }}>
                                    <td className='rowHeight m-0 p-0 '>
                                        <div className='centerStuff centerHorizontal flex-grow-0 m-0 p-0'>
                                            <Avatar initials={`${data.firstName[0]}${data.lastName[0]}`} size={44} />
                                        </div>
                                    </td>
                                    <td className='rowHeight'><p className='is-size-6 centerStuff'>{`${data.firstName} ${data.lastName}`}</p></td>
                                    <td className='rowHeight is-hidden-mobile'><p className='is-size-6 centerStuff'><TagRole contactRole={data.role} /></p></td>
                                    <td className='rowHeight is-hidden-mobile'><p className='is-size-6 centerStuff'>{dateHelper(data.lastUpdatedOn)}</p></td>
                                    {/* <td className='rowHeight'><p className='is-size-6 centerStuff'>{data.userDetails?.firstName} {data.userDetails?.lastName}</p></td> */}
                                </tr>
                            );
                        })}
                        </>
                    </thead>
                </table>
            </div>
            </>   
        );
  };

export default DashboardDataRecent;