import React, { useEffect, useState } from 'react';
import NavPrivate from './NavPrivate';
import Footer from './Footer';
import ContactHelpLink from './ContactHelpLink';
import FilterSortModals from './FilterSortModals';
import JobsCard from './JobsCard';
import Pagination from './Pagination';
import axios from 'axios';
import GoogleMapJobs from './GoogleMapJobs';
import { useSearchParams } from 'react-router-dom';
import JobPostingToast from './ConfirmationToast';
import { CheckSession } from './Auth-Api';

const JobsBoard = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [toastMessage, setToastMessage] = useState('');

    const [pages, setPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [jobs, setJobs] = useState([]);
    const [totalJobs, setTotalJobs] = useState();
    const [sort, setSort] = useState('createdOn');
    const [sortOrder, setSortOrder] = useState(-1);
    const [active, setActive] = useState(true);

    const [filterQry, setFilterQry] = useState('');
    
    const [employers, setEmployers] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState(null);

    const [filters, setFilters] = useState({
        Status: ['Active', 'Archived'],
    });

    const [selectedLocation, setSelectedLocation] = useState(null);

    CheckSession();

    const handleCardClick = (location) => {
        console.log("Card clicked:", location);
        if (location && !isNaN(location.lat) && !isNaN(location.lng)) {
            setSelectedLocation(location);
        } else {
            console.error("Invalid location data:", location);
        }
    };

    const sortOptions = ['Most recent', 'Alpha A-Z', 'Alpha Z-A'];

    const handleFilterChange = (updatedFilters) => {
        // Handle the filter change, e.g., update search results
        if ((updatedFilters.Status.Active && updatedFilters.Status.Archived) || (!updatedFilters.Status.Active && !updatedFilters.Status.Archived)) {
            setActive(undefined);
        } else if (updatedFilters.Status.Active) {
            setActive(true);
        } else if (updatedFilters.Status.Archived) {
            setActive(false);
        }

        let filterStr = '';
        for (let i = 0; i < employers.length; i++) {
            const e = employers[i];

            if (updatedFilters.Employer && updatedFilters.Employer[e.nameEmployer]) {
                filterStr += `&employers=${e.id}`;
            }
        }

        setFilterQry(filterStr);
        setSelectedFilters(updatedFilters);
    };

    const handleSortChange = (newSort) => {
        switch (newSort) {
            case 'Most recent':
                setSort('createdOn');
                setSortOrder('-1');
                setCurrentPage(1);
                break;
            case 'Alpha A-Z':
                setSort('jobTitle');
                setSortOrder('1');
                setCurrentPage(1);
                break;
            case 'Alpha Z-A':
                setSort('jobTitle');
                setSortOrder('-1');
                setCurrentPage(1);
                break;
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page)
    }

    useEffect(() => {
        const id = searchParams.get('showToast');
        if (id) {
            setToastMessage(id);
        }

        axios.get(`/api/employers?page=1&limit=1000&sort=nameEmployer&active=true`)
        .then(res => {
            const emps = res.data.data;
            const ids = {};
            const names = [];
            emps.forEach(p => {
                ids[p.nameEmployer] = true;
                names.push(p.nameEmployer);
            });

            setEmployers(emps);
            
            setSelectedFilters({
                Employer: ids,
                Status: { 'Active': true, 'Archived': false },
            });

            setFilters({
                Employer: names,
                Status: ['Active', 'Archived']
            });
        })
        .catch(err => {
            console.log('err', err);
            alert('Error');
        });
    }, []);

    useEffect(() => {
        const url = `/api/jobs?page=${currentPage}&limit=12&sort=${sort}&sortOrder=${sortOrder}&active=${active}${filterQry}`;
        axios.get(url)
        .then(res => {
            console.log('res', res);
            setPages(res.data.metaData.totalPages);
            setCurrentPage(parseInt(res.data.metaData.pageNumber || 1));
            setTotalJobs(res.data.metaData.totalDocuments || 0);
            setJobs(res.data.data);
        })
        .catch(err => {
            // TODO JASON: handle err
            console.log('err', err);
        });
    }, [currentPage, sort, sortOrder, active, filterQry]);

    return (
        <section className="backgroundLightGreyColor theme-light section m-0 p-0" data-theme="light">
            <div className="container">
                <div className="backgroundLightGreyColor fullHeight is-flex is-flex-direction-column is-justify-content-space-between">
                    <div>
                        <NavPrivate />
                        <div className="theme-light is-flex is-flex-direction-column is-justify-content-center is-align-items-center columns m-0 pt-6">
                            <div className="column is-full is-mobile m-0 p-0">
                                <div>
                                    <div className="m-0 p-0">
                                        <div className="is-flex is-flex-direction-column is-align-content-flex-start my-4">
                                            <div className='is-flex is-flex-direction-row is-fullwidth is-justify-content-space-between is-align-items-center'>
                                                <h1 className="pl-4 is-size-3 has-text-weight-bold has-text-black">Jobs <span className="is-size-5 has-text-weight-normal"> ({totalJobs})</span></h1>
                                                <div className='m-0 p-0 is-flex is-flex-direction-row is-align-items-center'>
                                                {selectedFilters && <FilterSortModals 
                                                    filters={filters} 
                                                    initialSelectedFilters={selectedFilters}
                                                    sortOptions={sortOptions}
                                                    initialSort="Most recent"
                                                    onFilterChange={handleFilterChange}
                                                    onSortChange={handleSortChange}
                                                    hideShow=""
                                                />}
                                                    <ContactHelpLink />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='pb-2 is-flex is-flex-direction-rows is-flex-wrap-wrap is-justify-content-space-between'>
                                            <div className='column is-flex is-half is-full-mobile is-flex-direction-rows is-flex-wrap-wrap'>
                                                {jobs.map((job, i) => (
                                                    <JobsCard
                                                        key={i}
                                                        {...job}
                                                        onCardClick={handleCardClick}
                                                    />
                                                ))}
                                            </div>
                                            <div className='column is-flex is-flex-direction-rows'>
                                                <div className='column boxDiv borderAdd'>
                                                    <GoogleMapJobs
                                                        selectedLocation={selectedLocation}
                                                        locations={jobs.map(job => {
                                                            const lat = parseFloat(job.locationDetails.locationLat);
                                                            const lng = parseFloat(job.locationDetails.locationLong);
                                                            return {
                                                                jobId: job.id,
                                                                name: job.employerDetails?.nameEmployer || '',
                                                                address: job.locationDetails?.addressPrimaryStreet || '',
                                                                city: job.locationDetails?.addressPrimaryCity || '',
                                                                zip: job.locationDetails?.addressPrimaryZip || '',
                                                                lat: isNaN(lat) ? 0 : lat,
                                                                lng: isNaN(lng) ? 0 : lng
                                                            };
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {totalJobs > 12 && (
                                            <Pagination pages={pages} currentPage={currentPage} updatePage={handlePageChange} />
                                        )}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {toastMessage && <JobPostingToast toastMessage={toastMessage} close={() => {setToastMessage('')}}/>}
                    <Footer /> 
                </div>
            </div>
        </section>
    )
}

export default JobsBoard;