import React, { useContext } from 'react';
import Auth from '../utils.js/Auth';
import { signout } from './Auth-Api';
import WordDisplay from './WordDisplay';
import NavMobile from './NavMobile';


const Menu = () => {
    const auth = useContext(Auth);
    const handleLogout = async () => {
        const result = await signout();
        auth.setAuth(result.data.auth);
    }

    const appSections = {
        words: [
            { page: "Dashboard", url: "/#/dashboard" },
            { page: "Contacts", url: "/#/contacts" },
            { page: "Jobs", url: "/#/jobs" },
            { page: "Skills", url: "/#/skills" },
            { page: "Settings", url: "/#/settings" },
            { page: "Create", url: "/#/create" },
            { page: "Search", url: "/#/search" },
            { page: "Account", url: "/#/account" },
        ]
    };
    
    return (
        <section className="backgroundLightBlueColor theme-light section m-0 p-0" data-theme="light">
            <div className="fullHeight columns is-flex is-flex-direction-column is-justify-content-start is-align-items-center m-0 p-0">
                <NavMobile />
                <WordDisplay {...appSections} />
            </div>
        </section>
    )
}

export default Menu;