import React from "react";
import TagDisplay from "./TagDisplay";

const SkillsInfo = ({
    providerDetails,
    skillTitle,
    skillDescriptionShort,
    skillTags,
    skillCategory,
}) => {

    return (
        <>
        <p className='m-0 p-0 is-size-7 has-text-weight-bold colorLightGreen'>{providerDetails?.nameProviderLong}</p>
        <h1 className="mb-1 p-0 is-size-3 has-text-weight-bold colorDarkGrey">{skillTitle}</h1>
        <p className='m-0 p-0 is-size-5 has-text-weight-normal'>{skillDescriptionShort}</p>
        <p className='mb-2 pt-4 is-size-5'><TagDisplay TagsContent={skillTags} /></p>
        </>
    );
};

export default SkillsInfo;



