import React from 'react';
import { dateHelper } from '../utils.js/utils';
import SSNDisplay from './SSNDisplay';

const ProfileContentClient = ({
    id,
    documents,
    clientInterviewDate, 
    clientInterviewer, 
    clientCaregiver, 
    clientCaregiverContact, 
    clientDOB,
    clientCaregiverRelationship,
    clientConservatorship,
    clientConservatorName,
    clientConservatorPhone,
    clientConservatorEmail,
    clientAddressCdlId,
    clientSSN,
    clientAddressUci,
    clientLivingSituation,
    clientAddressStreet,
    clientAddressCity,
    clientAddressState,
    clientAddressZip,
    clientTransportation,
    clientIncome,
    clientPayee,
    referralDetails,
    caseManagerDetails,
    createdOn
}) => {
    return (
        <>
        <div className='pt-6'>
            <h2 className='pl-4 is-size-5 has-text-weight-bold colorDarkGrey'>Referral 
                 <span className='is-size-6 has-text-weight-normal'> ({dateHelper(referralDetails?.createdOn)})</span>
            </h2>
        </div>
        {/* Row 1 - Two */}
        <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
            <div className='boxDiv oneHundredPercentWidth p-4 ml-0 mr-2 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Source</p></div>
                <div className=''>
                    <p className='is-size-6 has-text-weight-normal colorDarkGrey'>{referralDetails?.source}</p>
                </div>
            </div>
            <div className='boxDiv oneHundredPercentWidth p-4 ml-2 mr-0 my-2 is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Case manager</p></div>
                <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{caseManagerDetails?.firstName} {caseManagerDetails?.lastName}</p></div>
            </div>
        </div>
        {/* Row 2 - Two */}
        <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
            <div className='boxDiv oneHundredPercentWidth p-4 ml-0 mr-2 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Intake date</p></div>
                <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{dateHelper(clientInterviewDate)}</p></div>
            </div>
            <div className='boxDiv oneHundredPercentWidth p-4 ml-2 mr-0 my-2 is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Interviewer</p></div>
                <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientInterviewer}</p></div>
            </div>
        </div>

        {clientCaregiver ? (
        <>
            <div className='pt-6'>
                <h2 className='pl-4 is-size-5 has-text-weight-bold colorDarkGrey'>Caregiver info</h2>
            </div>
            {/* Row 3 - One */}
            <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Caregiver name</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientCaregiver}</p></div>
                </div>
            </div>
            {/* Row 4 - Two */}
            <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <div className='boxDiv oneHundredPercentWidth p-4 ml-0 mr-2 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Emergency phone</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientCaregiverContact}</p></div>
                </div>
                <div className='boxDiv oneHundredPercentWidth p-4 ml-2 mr-0 my-2 is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Relationship</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientCaregiverRelationship}</p></div>
                </div>
            </div> 
        </>
        ) : (
            <span></span>
        )}

        {clientConservatorship ? (
        <>
            <div className='pt-6'>
                <h2 className='pl-4 is-size-5 has-text-weight-bold colorDarkGrey'>Conservatorship info</h2>
            </div>
            {/* Row 3 - One */}
            <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Conservator</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientConservatorName}</p></div>
                </div>
            </div>
            {/* Row 4 - Two */}
            <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <div className='boxDiv oneHundredPercentWidth p-4 ml-0 mr-2 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Phone</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientConservatorPhone}</p></div>
                </div>
                <div className='boxDiv oneHundredPercentWidth p-4 ml-2 mr-0 my-2 is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Email</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientConservatorEmail}</p></div>
                </div>
            </div> 
        </>
        ) : (
            <span></span>
        )}
        
        <div className='pt-6'>
            <h2 className='pl-4 is-size-5 has-text-weight-bold colorDarkGrey'>Additional</h2>
        </div>
        {/* Row 5 - Two */}
        <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
            <div className='boxDiv oneHundredPercentWidth p-4 ml-0 mr-2 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Date of birth</p></div>
                <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{dateHelper(clientDOB)}</p></div>
            </div>
            <div className='boxDiv oneHundredPercentWidth p-4 ml-2 mr-0 my-2 is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>CDL/ID</p></div>
                <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientAddressCdlId}</p></div>
            </div>
        </div>
        {/* Row 6 - Two */}
        <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
            <div className='boxDiv oneHundredPercentWidth p-4 ml-0 mr-2 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>SSN</p></div>
                <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'><SSNDisplay clientSSN={clientSSN} /></p></div>
            </div>
            <div className='boxDiv oneHundredPercentWidth p-4 ml-2 mr-0 my-2 is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>UCI</p></div>
                <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientAddressUci}</p></div>
            </div>
        </div>
        {!clientConservatorship ? (
            <>
            {/* Row New - One */}
            <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                    <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Conservatorship</p></div>
                    <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>No</p></div>
                </div>
            </div>
            </>
        ) : (
            <span></span>
        )}

        {/* Row 7 - One */}
        <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
            <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Current living situation</p></div>
                <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientLivingSituation} <span className='ml-4'>{clientAddressStreet} {clientAddressCity} {clientAddressState} {clientAddressZip}</span></p></div>
            </div>
        </div>
        {/* Row 8 - One */}
        <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
            <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Mode of transportation</p></div>
                <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientTransportation}</p></div>
            </div>
        </div>
        {/* Row 9 - One */}
        <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
            <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Source of income</p></div>
                <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientIncome}</p></div>
            </div>
        </div>
        {/* Row 10 - One */}
        <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
            <div className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-start is-align-items-center'>
                <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'>Payee</p></div>
                <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'>{clientPayee}</p></div>
            </div>
        </div>
        <div className='pt-6 is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
            <h2 className='pl-4 is-size-5 has-text-weight-bold colorDarkGrey'>Documents</h2>
            <div className='pr-4 is-size-6'><a href={`/#/add-document?id=${id}`}>Upload document</a></div>
        </div>
        {/* Row 3 - One */}
        <div className='is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
            {documents.map((d, i) => {
                return (
                    <div key={i} className='boxDiv oneHundredPercentWidth p-4 mx-0 my-2 is- is-flex is-flex-direction-rows is-flex-wrap-nowrap is-justify-content-space-between is-align-items-center'>
                        <div className='mr-5'><p className='is-size-6 has-text-weight-bold colorLightGreen'><a href="#">{d.fileName}</a>  <span className='has-text-weight-normal colorBlack is-size-6 ml-3'>(Added {dateHelper(d.createdOn)})</span></p></div>
                        <div className=''><p className='is-size-6 has-text-weight-normal colorDarkGrey'><a href="#">Download</a></p></div>
                    </div>
                )
            })}
        </div>
    </>
    );
};

export default ProfileContentClient;