import React, { useState, useEffect, useRef } from 'react';

const JobTransportation = ({transportation}) => {
    
  return (
    <>
    <div className="p-4">
        <p className='is-size-7 has-text-weight-bold colorLightGreen'>Transportation</p>
        <p className='is-size-4 has-text-weight-bold colorDarkGrey pt-2'>Options Available</p>
        <p className='is-size-6 colorDarkGrey py-2'>This location is accessible via public transportation. </p>
        <p className='is-size-6 colorDarkGrey has-text-weight-bold py-2'>{transportation} </p>
        <p className='is-size-6 colorDarkGrey py-2'>Please refer to Google Maps and transit services for the exact schedules.</p>
    </div>
    </>
  );
};

export default JobTransportation;